import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Testimonials.module.css";
import Star from "../../assets/images/testimonialStar.svg";
import Patrick1 from "../../assets/images/testimonials/patrick.png";
import Patrick2 from "../../assets/images/testimonials/patrick2.png";
import Patrick3 from "../../assets/images/testimonials/patrick3.png";
import iconA from "../../assets/images/testimonials/A.png";
import iconB from "../../assets/images/testimonials/B.png";
import iconE from "../../assets/images/testimonials/E.png";
import iconJ from "../../assets/images/testimonials/J.png";
import iconL from "../../assets/images/testimonials/L.png";
import iconM from "../../assets/images/testimonials/M.png";
import iconN from "../../assets/images/testimonials/N.png";
import iconS from "../../assets/images/testimonials/S.png";
import iconU from "../../assets/images/testimonials/U.png";
import GetInTouch from "../../components/GetInTouch";
import Reviews from "../../components/reviews";
import TestimonialVideo from "../../assets/videos/testimonialVideo.mp4";

const Testimonials = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <div>
      <Container>
        <p className={styles.heading}>Testimonials</p>
        <Row>
          <Col />
          <Col lg={9} sm={12}>
            <p className={styles.text}>
              Hear from our valued patients about their best experiences at
              Oaklands. Happy clients make us happy and their smiles tell about
              the quality of our care.
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center" }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div>
        <div className={styles.reviewsContainer}>
          <Reviews
            reviewsMarginTop={620}
            // reviewsMarginLeft={194}
            prevArrowLeft="25%"
            prevprevArrowTop={1050}
            nextArrowLeft="73%"
            nextArrowTop={1050}
          />
        </div>
        {/* <Container className={styles.videoContainer}>
          <video className={styles.video} controls autoplay muted>
            <source src="/sample-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Container> */}
        <Row className={styles.feedbackContainer}>
          <Col lg={4} sm={12}>
            <div className={styles.desktopStar}>
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
            </div>
            <p className={styles.title}>
              Stress-Free and Painless Filling Treatment
            </p>
            <p className={styles.desc}>
              I got recommended Oaklands from a friend. I attended on 26/07/2023
              and had a private filling treatment from Dr.Harun and his lovely
              team of nurses. The treatment was like no other very stressfree
              and painless would highly recommend for the service provided and
              the care. Thanks for Dr.Harun and the nurses for taking care of
              me.
            </p>
            <div style={{ marginTop: 26, display: "flex", gap: 20 }}>
              <div>
                <img src={iconU} alt="patrick" />
              </div>
              <div>
                <p className={styles.name}>Usaama Mohammed</p>
                {/* <p className={styles.desig}></p> */}
                <div className={styles.mobileStar}>
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className={styles.desktopStar}>
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
              <img src={Star} alt="star" />
            </div>
            <p className={styles.title}>
              Exceptional Care and Supportive Staff
            </p>
            <p className={styles.desc}>
              I have always found the staff to be very pleasant and helpful. The
              dentist has been absolutely great with me . I had to have a lot of
              work done due to the effects of an illness I had a few years ago
              and he has always seen me at short notice, phoned me to see
              everything was okay with my treatment and very patient as I am a
              bit of a scary cat when it comes to going to the dentist. Thank
              you.
            </p>
            <div style={{ marginTop: 26, display: "flex", gap: 20 }}>
              <div>
                <img src={iconM} alt="patrick" />
              </div>
              <div>
                <p className={styles.name}>Margaret Casson</p>
                {/* <p className={styles.desig}></p> */}
                <div className={styles.mobileStar}>
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                  <img src={Star} alt="star" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className={styles.columns}>
              <div style={{ display: "flex" }}>
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
              </div>
              <p className={styles.title}>
                Professional and Successful Invisalign Treatment
              </p>
              <p className={styles.desc}>
                I have just finished a course of Invisalign bracers treatment
                with Oaklands Dental. The staff are very professional and
                accommodating. The treatment went well and was monitored well
                throughout. I am very pleased with the results that have come
                from the treatment also.
              </p>
              <div style={{ marginTop: 26, display: "flex", gap: 20 }}>
                <div>
                  <img src={iconJ} alt="patrick" />
                </div>
                <div>
                  <p className={styles.name}>John Mark</p>
                  {/* <p className={styles.desig}>Product Designer at COVEO</p> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={styles.feedbackContainer}>
          <Col lg={4} sm={12}>
            <div className={styles.columns}>
              <div style={{ display: "flex" }}>
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
              </div>
              <p className={styles.title}>
                Transformative Smile Makeover with Dr. Hussain
              </p>
              <p className={styles.desc}>
                Recently had my teeth cleaned, whitened and had veneers fitted
                with Dr Hussain. I've always hated my teeth and after having a
                bike accident and snapping my front two I was even more
                self-conscious of them. He's done an amazing job they look
                better than my natural teeth did. He's very calm and reassuring
                and kept checking in while he worked to make sure nothing was
                hurting. I can't believe how good they look. They went from
                yellow, stained wonky and cracked to white and straight. Best
                money I've ever spent - they look perfect. Thank you Dr Hussain
                for making me happy to smile.
              </p>
              <div style={{ marginTop: 26, display: "flex", gap: 20 }}>
                <div>
                  <img src={iconL} alt="patrick" />
                </div>
                <div>
                  <p className={styles.name}>Lydia Booth</p>
                  {/* <p className={styles.desig}>Product Designer at COVEO</p> */}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className={styles.columns}>
              <div style={{ display: "flex" }}>
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
              </div>
              <p className={styles.title}>
                Exceptional Invisalign Journey with Dr. Hussain{" "}
              </p>
              <p className={styles.desc}>
                Dr Hussain has been attentive throughout my Invisalign journey.
                He has patience of a saint and was helpful in answering all my
                questions and concerns I had regarding Invisalign which also
                included specific advice in relation the ‘complexity’ of an
                overbite in my case. Dr Hussain provided exceptional client care
                service to include telephone advice and email to suit my work
                commitments. Dr Hussain provided the upmost professional service
                at all times whilst making sure I felt at ease at all my
                appointments. I would recommend Dr Hussain to anyone who is
                thinking about Invisalign as you will not only leave with a
                beautiful smile at the end but also feel very exceptionally
                pleased with the outcome.
              </p>
              <div style={{ marginTop: 26, display: "flex", gap: 20 }}>
                <div>
                  <img src={iconN} alt="patrick" />
                </div>
                <div>
                  <p className={styles.name}>Naheed</p>
                  {/* <p className={styles.desig}>Product Designer at COVEO</p> */}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} sm={12}>
            <div className={styles.columns}>
              <div style={{ display: "flex" }}>
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
                <img src={Star} alt="star" />
              </div>
              <p className={styles.title}>
                Wonderful Invisalign Experience with a Welcoming Team
              </p>
              <p className={styles.desc}>
                I recently had a wonderful experience at this office. From the
                moment I entered I was welcomed by an extremely receptive and
                professional team, especially Julie. I had the Invasalign
                procedure which went really well and I am very happy with the
                result.
              </p>
              <div style={{ marginTop: 26, display: "flex", gap: 20 }}>
                <div>
                  <img src={iconE} alt="patrick" />
                </div>
                <div>
                  <p className={styles.name}>Eliane Guimaraes</p>
                  {/* <p className={styles.desig}>Product Designer at COVEO</p> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container>
          <div className={styles.pagination}>
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default Testimonials;
