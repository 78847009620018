import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetBackground from "../../assets/images/getBackground.jpeg";
import styles from "./GetInTouch.module.css";

const GetInTouch = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Form state variables
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the threshold as needed
    };
    checkIsMobile(); // Check initially
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
      return "Please enter a valid email address.";
    }
    if (email.includes("..")) {
      return "Email address cannot contain consecutive dots.";
    }
    return "";
  };

  const validatePhoneNumber = (number) => {
    const regex = /^(?:0|\+44)(?:\d\s?){9,10}$/;
    if (!regex.test(number)) {
      return "Please enter a valid UK phone number.";
    }
    return "";
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (!/^\+?\d*$/.test(value)) {
      return; // Prevent invalid characters
    }
    setPhoneNumber(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    const emailError = validateEmail(email);
    const phoneError = validatePhoneNumber(phoneNumber);

    setEmailError(emailError);
    setPhoneError(phoneError);

    if (!emailError && !phoneError) {
      // Success case
      try {
        const response = await fetch(
          "http://micarepoc.grayphite.com/api/contact",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              phone: phoneNumber,
              email: email,
              message: message,
              company_email: "huddsdental@outlook.com",
            }),
          }
        );

        if (response.ok) {
          toast.success("Form submitted successfully!", {
            position: "top-right",
          });
          // Clear form
          setFirstName("");
          setLastName("");
          setPhoneNumber("");
          setEmail("");
          setMessage("");
        } else {
          toast.error("Error submitting form. Please try again.", {
            position: "top-right",
          });
        }
      } catch (error) {
        toast.error("Error submitting form. Please try again.", {
          position: "top-right",
        });
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {isMobile ? (
        <>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <img
                  style={{ borderRadius: 71, textAlign: "center" }}
                  src={GetBackground}
                  width="100%"
                  alt="get background"
                />
              </Col>
            </Row>
          </Container>
          <div style={{ position: "relative" }}>
            <Container className={styles.formContainerMobile}>
              <Container>
                <div style={{ paddingTop: 40 }}>
                  <p className={styles.formHeading}>get in touch</p>
                </div>
                <Form style={{ marginTop: 43 }} onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={12} lg={6}>
                      <Form.Group>
                        <Form.Control
                          className={styles.inputText}
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} style={{ marginTop: 24 }}>
                      <Form.Group>
                        <Form.Control
                          className={styles.inputText}
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} lg={6} style={{ marginTop: 24 }}>
                      <Form.Group controlId="formGridEmail">
                        <Form.Control
                          style={{ marginTop: -6 }}
                          className={styles.inputText}
                          type="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={handleEmailChange}
                          isInvalid={!!emailError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {emailError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={12} lg={6} style={{ marginTop: 24 }}>
                      <Form.Group>
                        <Form.Control
                          className={styles.inputText}
                          type="text"
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={handlePhoneChange}
                          isInvalid={!!phoneError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {phoneError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Control
                    className={styles.inputText}
                    as="textarea"
                    placeholder="Type here"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ height: "145px", marginTop: 24 }}
                  />
                  <div style={{ marginTop: 27, marginBottom: 56 }}>
                    <button className={styles.button} type="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Container>
            </Container>
          </div>
        </>
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col xs={1} />
              <Col xs={7}>
                <img
                  style={{ borderRadius: 71 }}
                  src={GetBackground}
                  height={800}
                  alt="get background"
                />
              </Col>
              <Col xs={4} />
            </Row>
          </Container>
          <Container className={styles.formContainer}>
            <Container>
              <div style={{ paddingTop: 73 }}>
                <p className={styles.formHeading}>get in touch</p>
              </div>
              <Form style={{ marginTop: 43 }} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Control
                      className={styles.inputText}
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Control
                      className={styles.inputText}
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control
                      style={{ marginTop: -6 }}
                      className={styles.inputText}
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      isInvalid={!!emailError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} style={{ marginTop: -6 }}>
                    <Form.Control
                      className={styles.inputText}
                      type="text"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      isInvalid={!!phoneError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {phoneError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Form.Control
                  className={styles.inputText}
                  as="textarea"
                  placeholder="Type here"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ height: "178px", marginTop: -6 }}
                />
                <div style={{ marginTop: 40, marginBottom: 50 }}>
                  <button className={styles.button} type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            </Container>
          </Container>
        </>
      )}
    </div>
  );
};

export default GetInTouch;
