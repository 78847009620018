import React from "react";
import NavBar from "../Navbar";
import styles from './Layout.module.css'
import Footer from "../footer";
import GetInTouch from "../GetInTouch";

const Layout = (props) => {
  return (
    <>
      <NavBar />
      {props.children}
      <div className={styles.footerContaner}>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
