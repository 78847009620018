import React, { useEffect, useState } from "react";
import styles from "./Home.module.css";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import divider from "../../assets/images/divider.png";
import star from "../../assets/images/Star.png";
import profiles from "../../assets/images/profilesAvatar.png";
import doctor from "../../assets/images/doctor.png";
import SolutionsIcon from "../../assets/images/solutionsIcon.png";
import whoWeAre from "../../assets/images/home/WHO WE ARE.png";
import solutions from "../../assets/images/home/SOLUTIONS.png";
import Boutique from "../../assets/images/solutions/boutique.png";
import Family from "../../assets/images/solutions/family.png";
import Implants from "../../assets/images/solutions/implants.png";
import Hygiene from "../../assets/images/solutions/hygien.png";
import Invisalign from "../../assets/images/solutions/invisalign.png";
import MinorSugar from "../../assets/images/solutions/minorSugar.png";
import Orthodontics from "../../assets/images/solutions/orthodontics.png";
import Restorative from "../../assets/images/solutions/restorative.png";
import Smile from "../../assets/images/solutions/smile.png";
import Experience from "../../assets/images/home/experience.png";
import Check from "../../assets/images/home/check.png";
import Clinic from "../../assets/images/home/clinic.png";
import Chatboat from "../../assets/images/home/chatBoat.png";
import GetInTouch from "../../components/GetInTouch";

import Reviews from "../../components/reviews";

const Home = () => {
  const temp = 1;
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <>
      <Container>
        <Row className={styles.heroSection}>
          <Col lg={6} sm={12}>
            <p className={styles.heroHeading}>Your new smile starts here</p>
            <p className={styles.heroText}>
              At Oaklands Dental we cater for all your dental needs from being a
              leading implant clinic to also offering all forms of cosmetic
              dentistry. Make your way to a confident smile and step forward to
              the finest optimal oral health solution today. Our team of experts
              will assist you in getting the smile of your dreams.
            </p>
            <div className={styles.heroButtons}>
              <button className={styles.button} onClick={handleBooking}>
                Book an Appointment
              </button>
              {/* <button style={{}} className={styles.appointment}>
                An appointment
              </button> */}
            </div>
            <div className={styles.ratings}>
              <div>
                <p className={styles.ratingText}>Rating</p>
                <div className={styles.starRating}>
                  <p className={styles.ratingValue}>4.5</p>
                </div>
              </div>
              <div style={{ marginTop: 19 }}>
                <img src={divider} alt="divider" />
              </div>
              <div>
                <div style={{ marginTop: 40 }}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <img src={star} height={46} alt="star" />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            style={{ position: "relative" }}
            className={styles.doctorCol}
          >
            <div className={styles.desktopDoctor}>
              <img src={doctor} className={styles.docImg} alt="doctor" />
              <div className={styles.experience}>
                <div
                  style={{
                    display: "flex",
                    gap: 6,
                    marginLeft: 10,
                    marginTop: 8,
                  }}
                >
                  <div>
                    <img
                      src={Experience}
                      height={50}
                      className={styles.cardImg}
                      alt="experience"
                    />
                  </div>
                  <div style={{ marginBottom: 6 }}>
                    <p style={{ marginBottom: -3 }}>
                      <span className={styles.thirty}>30</span>
                      <span className={styles.plus}>+</span>
                    </p>
                    <span className={styles.years}>Years of experience</span>
                  </div>
                </div>
              </div>
              <div className={styles.maintain}>
                <div
                  style={{
                    display: "flex",
                    gap: 6,
                    marginLeft: 10,
                    marginTop: 15,
                  }}
                >
                  <div>
                    <img
                      src={Check}
                      className={styles.cardImg}
                      height={50}
                      alt="experience"
                    />
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <p style={{ marginBottom: -6 }}>
                      <span className={styles.thirty}>100%</span>
                    </p>
                    <span className={styles.years}>Maintain Dental</span>
                  </div>
                </div>
              </div>
              <div className={styles.clinic}>
                <div className={styles.dentalImgContainer}>
                  <img
                    src={Clinic}
                    height={50}
                    className={styles.cardImg}
                    alt="experience"
                  />
                </div>
                <div style={{ marginTop: 8, marginBottom: 12 }}>
                  <p className={styles.clinicText}>Oaklands Dental Clinic</p>
                </div>
              </div>
              {/* <div className={styles.chatBoat}>
              <img src={Chatboat} height={80} alt="chatboat" />
            </div> */}
            </div>
          </Col>
        </Row>
      </Container>
      {/* <div className={styles.desktopWelcome}>
        <Container>
          <div style={{ position: "relative" }}>
            <div className={styles.whoBackground}>
              <img
                src={whoWeAre}
                style={{ width: "100%", textAlign: "center" }}
                alt="who we are"
              />
            </div>
          </div>
          <p className={styles.trusted}>
            Your Trusted Partners in Dental Wellness
          </p>
        </Container>
        <Container>
          <Row className={styles.section2}>
            <Col lg={5} sm={12}>
              <img src={headerGroup2} className={styles.headerGroup2} />
            </Col>
            <Col lg={6} sm={12}>
              <p className={styles.desc2}>
                Welcome to Oaklands Dental, your trusted dental home in
                Huddersfield since the 1960s. operating by Dr. Haroon Hussain
                since 2020, we are committed to providing exceptional dental
                services, including routine checkups, cosmetic treatments, and
                other crucial procedures. By delivering stellar services, we
                have become one of the renowned dental solution providers in the
                area. Our team of experts ensures the best patient care without
                any hassle. It’s time to take care of yourself and your smile.
                Reach out to us to book your appointment and receive top-notch
                treatment. Let us be your partner on your journey to optimal
                health. We are here to help you achieve your dental goals.
              </p>
              <div className={styles.heroButtons}>
                <button
                  className={styles.startButton}
                  onClick={() => navigate("/contact-us")}
                >
                  Let’s Start
                </button>
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    cursor: "pointer",
                    width: "-webkit-fill-available",
                  }}
                  className={styles.appointment}
                  onClick={() => navigate("/about-us")}
                >
                  <button className={styles.button2}>Read more</button>
                  <img
                    src={rightArrow}
                    height={11}
                    className={styles.rightArrowAlign}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className={styles.solutionsContainer0}>
        <Container className={styles.solutionsContainer1}>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute" }}>
              <img
                src={solutions}
                style={{ textAlign: "center", width: "100%" }}
                alt="solutions"
              />
            </div>
            <p className={styles.solutionsHeading}>
              Comprehensive Dental Solutions for Every Need
            </p>
          </div>
        </Container>
        <Container className={styles.solutionsContainer}>
          <Row>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/implants")}
            >
              <div className={styles.solutionIcons}>
                <img src={Implants} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Implants</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Crown And Bridges</li>
                    <li>Dentures</li>
                  </ul>
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/smile")}
            >
              <div className={styles.solutionIcons}>
                <img src={Smile} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Smile Design</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Cosmetic Dental Procedures</li>
                  </ul>
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/clear-aligners")}
            >
              <div className={styles.solutionIcons}>
                <img src={Invisalign} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>CLEAR ALIGNERS</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Consultation</li>
                    <li>Treatment Planning</li>
                    <li>Aligner Fabrication</li>
                    <li>Wearing Aligners</li>
                  </ul>
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className={styles.solutionsRow2}>
          <Row className={styles.solutionsRow3}>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/composite-bonding")}
            >
              <div className={styles.solutionIcons}>
                <img src={Hygiene} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Composite Bonding</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Conceal Chips or Cracks</li>
                    <li>Camouflage Tooth Discoloration</li>
                  </ul>
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/services/teeth-whitening")}
            >
              <div className={styles.solutionIcons}>
                <img src={Boutique} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>TEETH WHITENING</p>
                <p className={styles.sectionText}>
                  {/* <ul>
                    <li>Teeth Whitening</li>
                  </ul> */}
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/restorative-dentistry")}
            >
              <div className={styles.solutionIcons}>
                <img src={Restorative} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Restorative Dentistry</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Fillings</li>
                    <li>Root Canal Treatments</li>
                  </ul>
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className={styles.solutionsRow2} id="solutionsLastRow">
          <Row className={styles.solutionsRow3}>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/family-care")}
            >
              <div className={styles.solutionIcons}>
                <img src={Family} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Family Care</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Prevention</li>
                  </ul>
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/minor-oral-surgery")}
            >
              <div className={styles.solutionIcons}>
                <img src={MinorSugar} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>Minor Oral Surgery</p>
                <p className={styles.sectionText}>
                  <ul>
                    <li>Extractions</li>
                  </ul>
                </p>
              </Container>
            </Col>
            <Col
              sm={12}
              lg={4}
              className={styles.solutionsCol}
              onClick={() => navigate("/services/sedation")}
            >
              <div className={styles.solutionIcons}>
                <img src={Orthodontics} height={116} alt="solutions-icon" />
              </div>
              <Container>
                <p className={styles.solutionsTitle}>
                  Sedation Services Available
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mobileWelcome}>
        <Container>
          <div style={{ position: "relative" }}>
            <div className={styles.whoBackground}>
              <img
                src={whoWeAre}
                style={{ width: "100%", textAlign: "center" }}
                alt="who we are"
              />
            </div>
          </div>
          <p className={styles.trusted}>
            Your Trusted Partners in Dental Wellness
          </p>
        </Container>
        <Container>
          <Row className={styles.section2}>
            <Col lg={5} sm={12}>
              <img src={headerGroup2} className={styles.headerGroup2} />
            </Col>
            <Col lg={6} sm={12}>
              <p className={styles.desc2}>
                Welcome to Oaklands Dental, your trusted dental home in
                Huddersfield since the 1960s. operating by Dr. Haroon Hussain
                since 2020, we are committed to providing exceptional dental
                services, including routine checkups, cosmetic treatments, and
                other crucial procedures. By delivering stellar services, we
                have become one of the renowned dental solution providers in the
                area. Our team of experts ensures the best patient care without
                any hassle. It’s time to take care of yourself and your smile.
                Reach out to us to book your appointment and receive top-notch
                treatment. Let us be your partner on your journey to optimal
                health. We are here to help you achieve your dental goals.
              </p>
              <div className={styles.heroButtons}>
                <button
                  className={styles.startButton}
                  onClick={() => navigate("/contact-us")}
                >
                  Let’s Start
                </button>
                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    cursor: "pointer",
                    width: "-webkit-fill-available",
                  }}
                  className={styles.appointment}
                  onClick={() => navigate("/about-us")}
                >
                  <button className={styles.button2}>Read more</button>
                  <img
                    src={rightArrow}
                    height={11}
                    className={styles.rightArrowAlign}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Col
          lg={6}
          sm={12}
          style={{ position: "relative" }}
          className={styles.doctorCol}
        >
          <div className={styles.mobileDoctor}>
            <img src={doctor} className={styles.docImg} alt="doctor" />
            <div className={styles.experience}>
              <div
                style={{
                  display: "flex",
                  gap: 6,
                  marginLeft: 10,
                  marginTop: 8,
                }}
              >
                <div>
                  <img
                    src={Experience}
                    className={styles.cardImg}
                    alt="experience"
                  />
                </div>
                <div style={{ marginBottom: 6 }}>
                  <p style={{ marginBottom: -3 }}>
                    <span className={styles.thirty}>30</span>
                    <span className={styles.plus}>+</span>
                  </p>
                  <span className={styles.years}>Years of experience</span>
                </div>
              </div>
            </div>
            <div className={styles.maintain}>
              <div
                style={{
                  display: "flex",
                  gap: 6,
                  marginLeft: 10,
                  marginTop: 15,
                }}
              >
                <div>
                  <img
                    src={Check}
                    className={styles.cardImg}
                    alt="experience"
                  />
                </div>
                <div style={{ marginBottom: 12 }}>
                  <p style={{ marginBottom: -6 }}>
                    <span className={styles.thirty}>100%</span>
                  </p>
                  <span className={styles.years}>Maintain Dental</span>
                </div>
              </div>
            </div>
            <div className={styles.clinic}>
              <div className={styles.dentalImgContainer}>
                <img src={Clinic} className={styles.cardImg} alt="experience" />
              </div>
              <div style={{ marginTop: 8, marginBottom: 12 }}>
                <p className={styles.clinicText}>Oaklands Dental Clinic</p>
              </div>
            </div>
            {/* <div className={styles.chatBoat}>
              <img src={Chatboat} height={80} alt="chatboat" />
            </div> */}
          </div>
        </Col>
      </Container>
      <Container className={styles.reviewsContainer}>
        <Reviews
          reviewsMarginTop={4155}
          reviewsMarginLeft={194}
          prevArrowLeft="25%"
          prevprevArrowTop={4285}
          nextArrowLeft="73%"
          nextArrowTop={4285}
        />
      </Container>
      <div className={styles.showMoreContainer}>
        <button
          className={styles.showMoreButton}
          onClick={() => navigate("/about-us/testimonials")}
        >
          Show More
        </button>
      </div>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </>
  );
};

export default Home;
