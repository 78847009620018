import React, { useState } from "react";
import styles from "./Invisalign.module.css";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const Invisalign = () => {
  const [temp, setTemp] = useState();
  return (
    <div>
      <SpecificService
        title1="Clear Aligners"
        title2="Clear Aligners Case Study"
        desc1="Having a perfect set of teeth makes a huge difference in your overall appearance. By getting Clear Aligners treatment, you experience quick results without an artificial look.. Experience the benefits of Clear Aligners now."
        desc2="Say hello to one of the best aligners’ treatments for your perfect smile. Enjoy a beautiful smile with clear braces. If you are on the lookout for a confident smile, we at Oaklands can assist you in achieving your goal. Clear Aligners have been recently introduced, reforming and transforming tooth straightening processes. They are almost invisible which makes them the best solution for those who don’t feel comfortable about the appearance of their teeth. These aligners are custom-made to fit in everyone’s mouth perfectly, ensuring there will not be any hassle later. Additionally, we provide full assistance and examination to assess whether they are the right option for you. Connect with us now in order to get the ideal results. "
        solutionsTop={-40}
      />
    </div>
  );
};

export default Invisalign;
