import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./postOrthodonticCare.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/5.jpg";

const PostOrthodonticCare = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}

        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              Beyond Braces: The Lifesaving Role of Retainers
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we believe that the journey to a perfect smile
              doesn’t end when your braces come off. In fact, it marks the
              beginning of an essential phase called post-orthodontic care. This
              phase is crucial for maintaining the beautiful results you’ve
              worked hard to achieve over the years. A key component of this
              phase is the use of retainers, which play a vital role in ensuring
              your teeth stay in their correct positions.
            </p>
            <p style={{ marginTop: 20 }}>
              Retainers come in various types, each with its own guidelines for
              use and care. After your braces are removed, your gums and bones
              are still adjusting to the new positions of your teeth, especially
              during the first six months. Even as your smile stabilizes over
              time, there’s always a risk of your teeth shifting. That’s why
              it’s so important to make retainers a consistent part of your
              routine from the day your braces come off.
            </p>
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we can’t emphasize enough the significance of
              retainers. Without proper use, teeth can gradually move back to
              their previous positions, undoing all the hard work of your
              orthodontic treatment. That’s why we stress the importance of
              wearing your retainer as directed by your orthodontist.
            </p>
            <h2 style={{ fontWeight: 700, marginTop: 20 }}>
              What Do Retainers Do?
            </h2>
            <p style={{ marginTop: 20 }}>
              Retainers hold your teeth in their proper position, helping to
              maintain your new smile during the critical post-braces phase when
              the tissues around your teeth are still adjusting. After your
              braces are removed, wearing a retainer at night is essential to
              keep your teeth straight and prevent them from shifting back to
              their original alignment. Retainers are typically made of metal or
              clear plastic and are designed to fit over your top or bottom
              teeth.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Types of Retainers
            </h2>
            <p style={{ marginTop: 20 }}>
              Retainers come in various types, each serving the same essential
              purpose of keeping your teeth in place after braces:
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>Removable Retainers:</span>{" "}
              These include the popular Hawley retainers, which are made of
              metal wires and acrylic. Removable retainers are convenient
              because they can be taken out during meals and for cleaning.
              However, they require a high level of responsibility, as they must
              be worn as directed by the orthodontist. While they are durable,
              they can be lost or damaged if not handled with care.
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>Fixed Retainers:</span> Also
              known as bonded or permanent retainers, these are attached
              directly to the backside of your teeth. Fixed retainers offer the
              benefit of continuous wear without requiring active management,
              making them an excellent option for those who may forget or be
              less diligent about wearing removable retainers. However, they
              require meticulous cleaning to prevent plaque buildup.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Retainers Care & Maintenance
            </h2>
            <p style={{ marginTop: 20 }}>
              Proper care and maintenance of your retainer are essential for
              both your oral health and the longevity of the tool. Removable
              retainers should be cleaned regularly with appropriate solutions
              to prevent bacterial buildup and odors. Fixed retainers require
              daily brushing and flossing to keep the area around the retainer
              clean and free of plaque.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              The Importance of Follow-Up Appointments
            </h2>
            <p style={{ marginTop: 20 }}>
              Regular follow-up appointments with your orthodontist after your
              braces are removed are crucial. These visits allow your
              orthodontist to monitor your progress and make any necessary
              adjustments to your retainer, ensuring it remains effective and
              comfortable. At Oaklands Dental, we are committed to helping you
              maintain the results of your orthodontic treatment, and follow-up
              appointments are a key part of that process.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>Conclusion</h2>
            <p style={{ marginTop: 20 }}>
              Retainers are an essential tool in preserving the beautiful smile
              you’ve worked so hard to achieve. Whether you have a removable or
              fixed retainer, consistent use and proper care are key to keeping
              your teeth straight and healthy. At Oaklands Dental, we’re here to
              support you every step of the way on your post-braces journey. If
              you have any questions or need advice on your retainer care, don’t
              hesitate to reach out to us. Your perfect smile is worth
              protecting!
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Post-Orthodontic Care</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Retainers</p>
                <p className={styles.tag}>Orthodontic Care</p>
                <p className={styles.tag}>Teeth Maintenance</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Post-Braces</p>
                <p className={styles.tag}>Smile Preservation</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default PostOrthodonticCare;
