import { Col, Container, Row } from "react-bootstrap";
import styles from "./Footer.module.css";
import ContactCard from "../ContactCard";
import { useNavigate } from "react-router-dom";
import Cqc from "../../assets/images/footer/cqc.svg";
import Nhs from "../../assets/images/footer/nhs.svg";
import Gdc from "../../assets/images/footer/gdc.jpeg";

function Footer() {
  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/home");
  };
  const handleAboutUs = () => {
    navigate("/about-us");
  };
  const handleTeam = () => {
    navigate("/about-us/team");
  };
  const handleContactUs = () => {
    navigate("/contact-us");
  };
  const handlePrivacy = () => {
    navigate("/information/privacy");
  };
  const handlePricing = () => {
    navigate("/information/pricing");
  };
  const handleInformation = () => {
    navigate("/information");
  };
  const handleWhatWeOffer = () => {
    navigate("/information/pricing");
  };
  const handleModernSlavery = () => {
    navigate("/information/modern-slavery");
  };
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <>
      <footer>
        <div className={styles.contactCard}>
          <ContactCard />
        </div>
        <div className={styles.footerTopWhiteSpace}></div>
        <Row className={styles.contentMain} style={{ background: "#1f45b105" }}>
          <Col lg={2} />
          <Col lg={4} sm={12} className={styles.footerContent}>
            <div className={styles.contentMain}>
              {/* <p className={styles.footerContentGreeting}>
                <Container>
                  Welcome to Oaklands Dental. Based in the heart of
                  Huddersfield. Providing the best care for our patients 🦷
                  Independent dental clinic in Huddersfield, West Yorkshire
                  gentle and experienced dental treatment, Doctor-led facial
                  aesthetics.
                </Container>
              </p> */}
              <div className={styles.buttonContainer}>
                <button className={styles.button} onClick={handleBooking}>
                  Book an Appointment
                </button>
              </div>

              <ul className={styles.footerLink}>
                <Container>
                  <Row>
                    <Col className="footer-link-col1">
                      <li>
                        <a style={{ cursor: "pointer" }} onClick={handleHome}>
                          Home
                        </a>{" "}
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleAboutUs}
                        >
                          About Us
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleWhatWeOffer}
                        >
                          What We offer
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handlePrivacy}
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleModernSlavery}
                        >
                          Modern Slavery Policy
                        </a>
                      </li>
                    </Col>
                    <Col className={styles.footerLinkCol2}>
                      <li>
                        <a style={{ cursor: "pointer" }} onClick={handleTeam}>
                          Meet The Team
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handlePricing}
                        >
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleContactUs}
                        >
                          Contact Us
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleInformation}
                        >
                          Information For Patients
                        </a>
                      </li>
                    </Col>
                  </Row>
                </Container>
              </ul>
            </div>
          </Col>
          <Col className={styles.affiliated}>
            <p style={{ fontWeight: "bold", color: "#52575d" }}>Affiliated</p>
            <div>
              <img
                height={50}
                style={{ cursor: "pointer", marginTop: 20 }}
                src={Cqc}
                onClick={() =>
                  window.open(
                    "https://www.cqc.org.uk/guidance-providers/dentists",
                    "_blank"
                  )
                }
              />
            </div>
            <div>
              <img
                height={50}
                style={{ cursor: "pointer", marginTop: 20 }}
                src={Nhs}
                onClick={() => window.open("https://www.nhs.uk/", "_blank")}
              />
            </div>
            <div>
              <img
                height={70}
                style={{ cursor: "pointer", marginTop: 20 }}
                src={Gdc}
                onClick={() => window.open("https://www.gdc-uk.org/", "_blank")}
              />
            </div>
          </Col>
        </Row>
      </footer>
      <div className={styles.copywrightText}>
        Copyright 2024 All Rights Reserved by{" "}
        <a
          // href="https://oaklandsdentalhudds.co.uk/What%20we%20offer.html#"
          onClick={handleHome}
          style={{
            color: "#52575D",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {" "}
          Oaklands Dental
        </a>
        .
      </div>
    </>
  );
}

export default Footer;
