import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import styles from "./Minor.module.css";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const MinorOralSurgery = () => {
  const temp = 10;
  return (
    <SpecificService
      title1="Minor Oral Surgery"
      title2="Get high-quality and optimal results along with a comfortable experience"
      desc1="Are you looking for specialist dental services? We provide quality minor oral surgery in which the patient is given an injection into the gum. Connect with us and experience friendly, welcoming, and caring service.
      "
      desc2="You might have faced difficulty in tooth extraction or suffered from wisdom tooth pain. Don’t panic, we have you covered. We provide the best possible treatment delivered in the safest way possible. We prioritize your comfort and experienced surgeons follow the latest techniques to ensure safe procedures.  Our skilled surgeons have extensive experience in doing minor oral surgery procedures. If you wish to get the services, please do not hesitate to contact us and speak to our team. 
      "
      solutionsTop={13}
    />
  );
};

export default MinorOralSurgery;
