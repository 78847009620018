import React, { useState } from "react";
import SpecificService from "../../components/subService";

const CompositeBonding = () => {
  const [temp, setTemp] = useState();
  return (
    <div>
      <SpecificService
        title1="Composite Bonding"
        title2="Enjoy a brighter smile with composite bonding treatment at Oaklands"
        desc1="Get proper care with our minimally invasive cosmetic treatment that significantly improves your smile. Give us a call today or book online now."
        desc2="To determine if tooth bonding is right for you, book a consultation with one of our associate dentists and learn everything about it. Composite bonding is an effective and affordable treatment for minor imperfections. At Oaklands, we provide quality cosmetic dentistry services, including composite bonding. Our experts pay close attention to detail, ensuring the bonding seamlessly blends with your existing teeth. However, this process reshapes your teeth by incorporating a thin layer of tooth-coloured resin. We begin by carefully integrating the resin into your natural tooth shade; the process does not need anaesthesia. Look no further than Oaklands, and transform your smile with composite bonding today."
        solutionsTop={12}
      />
    </div>
  );
};

export default CompositeBonding;
