import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import styles from "./Smile.module.css";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const SmileDesign = () => {
  const temp = 10;
  return (
    <div>
      <SpecificService
        title1="Smile Design"
        title2="Having a blueprint for the smile you wish starts with the design process"
        desc1="Get complete assistance in bridging the gaps between dentistry and the digital revolution. We are experts in offering a wide range of smile design services. Connect now."
        desc2="Smile design is used to enhance the appearance of the teeth and gums. We always consider the client’s preferences, keeping in mind that everyone has a different smile structure and needs to be treated accordingly. Your satisfaction is our top priority and we always focus on this while giving a treatment. We begin by interviewing the patient to discuss exactly how they want their teeth to look. To get a beautiful smile, the dental structure is fixed before applying for smile design dentistry procedures. In this process, the top teeth are exposed and straightened first during the treatment and then tooth stains are removed, resulting in whiter and brighter teeth."
        solutionsTop={-20}
      />
    </div>
  );
};

export default SmileDesign;
