import React, { useEffect, useState } from "react";
import styles from "./Team.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Deborah from "../..//assets/images/team/Deborah.png";
import Faiqa from "../..//assets/images/team/Faiqa.png";
import Harun from "../..//assets/images/team/Harun.png";
import second from "../..//assets/images/team/2.png";
import third from "../..//assets/images/team/3.png";
import fourth from "../..//assets/images/team/4.png";
import fifth from "../..//assets/images/team/5.png";
import sixth from "../..//assets/images/team/6.png";
import seventh from "../..//assets/images/team/7.png";
import eight from "../..//assets/images/team/8.png";
import ninth from "../..//assets/images/team/9.png";
import GetInTouch from "../../components/GetInTouch";

const Team = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <div>
      <Container style={{ marginTop: 125 }} fluid="sm">
        <p className={styles.services}>Meet Our Team</p>
        <Row>
          <Col />
          <Col lg={9} sm={12}>
            <p className={styles.text}>
              At Oaklands, we have a team of the finest dentists who ensure
              high-quality care in every dental area.
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div>
      </Container>
      <Container className={styles.teamContainer}>
        <Row>
          <Col>
            <div className={styles.teamImage}>
              <img src={Harun} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Dr Harun Hussain</p>
              <h3 className={styles.title}>Clinical Lead</h3>
              {/* <p className={styles.description}>
                is the principal dentist at Oaklands Dental and graduated from
                the University of Leeds in 2015. Dr Hussain has a particular
                interest cosmetic dentistry and has completed a 1 year programme
                in advanced restorative and cosmetic dentistry with the
                pre-eminent Dr Chris Orr in London. He has also more recently
                embarked upon a post graduate diploma in dental implant and
                reconstructive surgery.
              </p> */}
            </Container>
          </Col>
          <Col>
            <div className={styles.teamImage}>
              <img src={second} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Dr Priya Sharry-Khan</p>
              <h3 className={styles.title}>Dentist</h3>
              {/* <p className={styles.description}>
                is the principal dentist at Oaklands Dental and graduated from
                the University of Leeds in 2015. Dr Hussain has a particular
                interest cosmetic dentistry and has completed a 1 year programme
                in advanced restorative and cosmetic dentistry with the
                pre-eminent Dr Chris Orr in London. He has also more recently
                embarked upon a post graduate diploma in dental implant and
                reconstructive surgery.
              </p> */}
            </Container>
          </Col>
          <Col className={styles.profileContainer}>
            <div className={styles.teamImage}>
              <img src={third} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Faezza Khan</p>
              <p className={styles.title}>Dental Therapist</p>
              {/* <p className={styles.description}>
                Dr Haider Malik Graduated from University of Sheffield and grew
                up locally in the Huddersfield area. Dr Malik is keen to develop
                further as a dentist so that he can continue providing care for
                patients. He has been on several courses to provide cosmetic
                treatment options as well as recently being accepted on to an
                implants Masters Degree which he hopes to start this year in
                order to eventually provide implants for patients. Outside of
                dentistry Haider enjoys playing regular 6-a-side football, as
                well as travelling and eating out with his family and friends
                which could be considered his main hobby!
              </p> */}
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className={styles.teamContainer}>
        <Row>
          <Col>
            <div className={styles.teamImage}>
              <img src={fourth} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Deborah Mansell</p>
              <h3 className={styles.title}>Dental Hygienist</h3>
              {/* <p className={styles.description}>
                is the principal dentist at Oaklands Dental and graduated from
                the University of Leeds in 2015. Dr Hussain has a particular
                interest cosmetic dentistry and has completed a 1 year programme
                in advanced restorative and cosmetic dentistry with the
                pre-eminent Dr Chris Orr in London. He has also more recently
                embarked upon a post graduate diploma in dental implant and
                reconstructive surgery.
              </p> */}
            </Container>
          </Col>
          <Col>
            <div className={styles.teamImage}>
              <img src={fifth} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Julie Dawson</p>
              <h3 className={styles.title}>Practice Manager</h3>
              {/* <p className={styles.description}>
                is the principal dentist at Oaklands Dental and graduated from
                the University of Leeds in 2015. Dr Hussain has a particular
                interest cosmetic dentistry and has completed a 1 year programme
                in advanced restorative and cosmetic dentistry with the
                pre-eminent Dr Chris Orr in London. He has also more recently
                embarked upon a post graduate diploma in dental implant and
                reconstructive surgery.
              </p> */}
            </Container>
          </Col>
          <Col className={styles.profileContainer}>
            <div className={styles.teamImage}>
              <img src={sixth} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Faria Khalid</p>
              <p className={styles.title}>Dental Nurse</p>
              {/* <p className={styles.description}>
                Dr Haider Malik Graduated from University of Sheffield and grew
                up locally in the Huddersfield area. Dr Malik is keen to develop
                further as a dentist so that he can continue providing care for
                patients. He has been on several courses to provide cosmetic
                treatment options as well as recently being accepted on to an
                implants Masters Degree which he hopes to start this year in
                order to eventually provide implants for patients. Outside of
                dentistry Haider enjoys playing regular 6-a-side football, as
                well as travelling and eating out with his family and friends
                which could be considered his main hobby!
              </p> */}
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className={styles.teamContainer}>
        <Row>
          <Col>
            <div className={styles.teamImage}>
              <img src={seventh} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Fraya Cairns</p>
              <h3 className={styles.title}>Dental Nurse</h3>
              {/* <p className={styles.description}>
                is the principal dentist at Oaklands Dental and graduated from
                the University of Leeds in 2015. Dr Hussain has a particular
                interest cosmetic dentistry and has completed a 1 year programme
                in advanced restorative and cosmetic dentistry with the
                pre-eminent Dr Chris Orr in London. He has also more recently
                embarked upon a post graduate diploma in dental implant and
                reconstructive surgery.
              </p> */}
            </Container>
          </Col>
          <Col>
            <div className={styles.teamImage}>
              <img src={eight} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Lyla Burton</p>
              <h3 className={styles.title}>Dental Nurse</h3>
              {/* <p className={styles.description}>
                is the principal dentist at Oaklands Dental and graduated from
                the University of Leeds in 2015. Dr Hussain has a particular
                interest cosmetic dentistry and has completed a 1 year programme
                in advanced restorative and cosmetic dentistry with the
                pre-eminent Dr Chris Orr in London. He has also more recently
                embarked upon a post graduate diploma in dental implant and
                reconstructive surgery.
              </p> */}
            </Container>
          </Col>
          <Col className={styles.profileContainer}>
            <div className={styles.teamImage}>
              <img src={ninth} className={styles.profileImage} />
            </div>
            <Container>
              <p className={styles.name}>Areeba Shaikh</p>
              <h3 className={styles.title}>Dentist</h3>
              {/* <p className={styles.description}>
                Dr Haider Malik Graduated from University of Sheffield and grew
                up locally in the Huddersfield area. Dr Malik is keen to develop
                further as a dentist so that he can continue providing care for
                patients. He has been on several courses to provide cosmetic
                treatment options as well as recently being accepted on to an
                implants Masters Degree which he hopes to start this year in
                order to eventually provide implants for patients. Outside of
                dentistry Haider enjoys playing regular 6-a-side football, as
                well as travelling and eating out with his family and friends
                which could be considered his main hobby!
              </p> */}
            </Container>
          </Col>
        </Row>
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default Team;
