import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./dentalHealthAwareness.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/6.jpg";

const DentalHealthAwareness = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}

        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              Recognizing the Common Symptoms of Tooth Decay
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we recognize that cavities—areas of damage
              caused by tooth decay—can lead to severe oral health issues if not
              addressed promptly. Tooth decay and cavities are essentially the
              same thing: cavities are the visible manifestation of tooth decay.
              These damaged areas can form holes in your teeth, potentially
              leading to toothaches, infections, and even tooth loss.
              Understanding the common causes of tooth decay, such as bacteria,
              sugary snacks and drinks, and poor oral hygiene, is crucial for
              prevention. Tooth decay can affect people of all ages, from
              children to adults and even infants. To avoid complications, daily
              dental visits and good brushing technique habits are the best ways
              to protect your teeth from cavities.
            </p>
            <h2 style={{ fontWeight: 700, marginTop: 20 }}>
              How to Recognize a Cavity?
            </h2>
            <p style={{ marginTop: 20 }}>
              When tooth decay is in its earliest stages, then there are no
              signs or symptoms which depicts the significance of regular dental
              checkups and cleanings. By using diagnostic tools, you can easily
              detect decay before you commence showing symptoms and, often,
              prevent it from progressing or even reverse it. However, here are
              some common signs of a cavity to watch for:
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              1. Sensitivity to Hot, Cold, Acidic, or Sweet Foods and Beverages
            </h2>
            <p style={{ marginTop: 20 }}>
              The early signs of a cavity are lingering sensitivity to cold and
              hot food and drinks. This sensitivity occurs because enamel
              erosion exposes the dentin underneath. Dentin contains small
              tubules that allow sensations to reach the nerves, resulting in
              discomfort that can last from a few seconds to several minutes
              after consuming certain foods or drinks.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>2. Tooth Pain</h2>
            <p style={{ marginTop: 20 }}>
              The second common symptom of a cavity is tooth pain, which can
              manifest as constant or occasional discomfort. Initially, it may
              be challenging to identify the problematic tooth, as the pain can
              seem to come from multiple teeth. However, if the pain becomes
              severe enough to keep you up at night, it may indicate that the
              decay has reached the pulp, containing living nerves and tissues.
              Even if the severe pain suddenly subsides, it's essential to seek
              dental care, as the nerve may have died, but the infection can
              still spread to the gums, other teeth, and jawbone, leading to
              further complications. Prompt dental attention is vital to save
              the tooth and prevent additional damage.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>3. Discoloration</h2>
            <p style={{ marginTop: 20 }}>
              Discoloration of the teeth can signal early tooth decay.
              Initially, you might notice white spots, which could be due to
              excessive fluoride or mineral loss. As the decay advances, these
              spots may turn brown or black. If you notice any changes in tooth
              color, it's essential to have the discoloration properly assessed.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              4. Discomfort When Chewing
            </h2>
            <p style={{ marginTop: 20 }}>
              If you experience pain or discomfort when biting, chewing, or
              applying pressure, especially in your molars, it may be a sign of
              a cavity. This pain occurs when tooth decay weakens the tooth
              structure, making everyday actions uncomfortable.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              5. Hole & Bad Breath
            </h2>
            <p style={{ marginTop: 20 }}>
              A cavity is a hole in your tooth and if you see a hole in your
              tooth or feel it with your tongue you have a cavity that requires
              it to be filled before it causes more severe complications. When
              the tooth gets weaker, it can definitely break or fracture. Bad
              breath is another major issue which sometimes traps food debris,
              which causes bacteria to go on a feeding frenzy. In addition to
              this, this can also leave you with a bad odor or taste in your
              mouth.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              How Oaklands Dental Can Help
            </h2>
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we are dedicated to helping you maintain
              excellent oral health. Regular dental checkups are crucial for
              detecting cavities early and preventing significant damage. If you
              experience any of the symptoms mentioned, contact Oaklands Dental
              for a thorough examination and appropriate treatment.
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Dental Health Awareness</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Tooth Decay</p>
                <p className={styles.tag}>Dental Symptoms</p>
                <p className={styles.tag}>Cavities</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Oral Health</p>
                <p className={styles.tag}>Early Detection</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default DentalHealthAwareness;
