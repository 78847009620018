import React, { useEffect, useState } from "react";
import styles from "./AboutUs.module.css";
import { Col, Container, Row } from "react-bootstrap";
import TrustedPartner from "../../components/TrustedPartner";
import Best from "../../assets/images/about/best.png";
import Professional from "../../assets/images/about/professional.png";
import Patient from "../../assets/images/about/patient.png";
import whoWeAre from "../../assets/images/about/WHO WE ARE.png";
import pic1 from "../../assets/images/about/service1.jpeg";
import pic2 from "../../assets/images/about/service2.jpeg";
import pic3 from "../../assets/images/about/service3.jpeg";
import GetInTouch from "../../components/GetInTouch";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const [temp, setTemp] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <div>
      <Container fluid="sm">
        <p className={styles.heading}>About Us</p>
        <Row>
          <Col />
          <Col lg={9} sm={12}>
            <p className={styles.text}>
              At, Oaklands Dental, we ensure to provide the perfect smile that
              lasts a lifetime. We are committed to providing various services
              and ensuring that your dental health is in the best hands.
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center" }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div>
      </Container>
      <div className={styles.trustedContainer}>
        <TrustedPartner />
      </div>
      <Container className={styles.factsContainer}>
        <div style={{ position: "relative" }}>
          <div className={styles.factsContainer2}>
            <img
              src={whoWeAre}
              style={{ textAlign: "center", width: "100%" }}
              alt="who we are"
            />
          </div>
        </div>
        <p className={styles.facts}>Facts about us</p>
      </Container>
      <Container className={styles.iconsContainer}>
        <Row>
          <Col sm={12} lg={4}>
            <div className={styles.aboutIcons}>
              <img src={Best} height={115} alt="about-icon" />
            </div>
            <p className={styles.factsTitle}>Transparency</p>
            <p className={styles.factsText}>
              Oaklands believes in complete transparency. Before proceeding with
              any treatment, we ensure that you comprehend why the specific
              treatment is being suggested to you. We aim to help patients
              achieve the best oral health.
            </p>
          </Col>
          <Col sm={12} lg={4}>
            <div className={styles.aboutIconsContainer}>
              <div className={styles.aboutIcons}>
                <img src={Professional} height={115} alt="about-icon" />
              </div>
              <p className={styles.factsTitle}>Professional Expertise</p>
              <p className={styles.factsText}>
                Enjoy every procedure with our complete care and expertise. By
                utilizing advanced dental technology, we figure out individual
                needs and offer speedy, painless treatment.
              </p>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div className={styles.aboutIconsContainer}>
              <div className={styles.aboutIcons}>
                <img src={Patient} height={115} alt="about-icon" />
              </div>
              <p className={styles.factsTitle}>Sterilization & Quality</p>
              <p className={styles.factsText}>
                Your smile is our top priority. We use quality products and
                materials for better patient care.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm>
            <img
              src={pic1}
              style={{ width: "100%", height: "auto" }}
              alt="pic-1"
            />
          </Col>
          {/* <Col sm>
          <img
            src={pic2}
            style={{ width: "100%", height: "auto" }}
            alt="pic-1"
          />
        </Col> */}
          <Col sm>
            <img
              src={pic2}
              style={{ width: "100%", height: "auto" }}
              alt="pic-1"
            />
          </Col>
          <Col sm>
            <img
              src={pic3}
              style={{ width: "100%", height: "auto" }}
              alt="pic-1"
            />
          </Col>
        </Row>
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default AboutUs;
