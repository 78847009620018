import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./preventionDentistry.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/2.jpg";

const PreventionDentistry = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}
        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              Beyond Brushing: The Critical Importance of Regular Dental Visits
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we believe that oral hygiene is not just about
              doing daily brushing and flossing, it is beyond that. You should
              know that your mouth tells a lot about your overall dental health.
              If you have poor dental hygiene, then it can lead to deeper issues
              like gum disease and tooth decay. However, it can also affect your
              other organs badly. Oral hygiene practices such as brushing and
              flossing help keep bacteria under control, but regular dental
              visits are crucial to maintain your oral health properly. We
              recommend that our patients visit us for cleanings at least twice
              a year to ensure the best possible oral hygiene and to improve
              their overall quality of life.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Why Regular Dental Check-Ups Are Crucial?
            </h2>
            <p style={{ marginTop: 20 }}>
              Regular dental checkups are vital for securing proper oral health
              and can also help in minimizing overall dental costs. We suggest
              that the average person schedule a checkup every 6 months which is
              generally necessary. However, the frequency of visits depends on
              numerous individual factors meaning some may need to visit their
              dentist more frequently than twice a year, while others need to
              visit only twice a year. For example, if you are consistently
              following a daily oral hygiene routine then you may find that you
              do not have to visit the dentist more often. Numerous elements can
              negatively affect oral health such as:
            </p>
            {/* <ul> */}
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Diet:</span> Consuming a diet
              that has high sugary foods and beverages could lead to tooth decay
              and other dental problems.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Smoking: </span>
              The use of tobacco, whether through smoking or other forms, is a
              significant contributor to various oral health issues.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Alcohol Consumption: </span>
              Excessive consumption of alcohol could be disastrous for oral
              health.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Bruxism: </span>
              Dealing with bruxism, the habitual teeth grinding that
              conventionally takes place during sleep can also impact your
              overall health, including your dental health.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Poor Sleep and Stress: </span>
              These factors can negatively affect overall health, including oral
              health.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              The Advantages of Routine Dental Check-Ups
            </h2>
            <p style={{ marginTop: 20 }}>
              Daily dental checkups are crucial to the health of your gums and
              teeth. At Oaklands Dental, we know that these visits can also save
              you time and money in the long run. We are going to look at the
              important benefits of regular dental check-ups:
            </p>
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>
                Comprehensive Oral Examination:
              </span>{" "}
              During your visit, our dentist will examine the health of your
              mouth, checking for signs of cavities, gum disease, infection,
              oral cancer, and other dental health issues. We may also use
              X-rays to identify problems that are not visible to the naked eye,
              such as cavities between teeth, jaw injuries, or bone decay.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>
                Professional Teeth Cleaning:
              </span>{" "}
              Our professional dental cleaning removes plaque and tartar that
              cannot be removed by flossing and brushing alone. If left
              untreated, plaque and tartar can lead to serious issues like tooth
              decay and gum inflammation. Regular cleanings are far more
              convenient and less invasive than procedures like fillings or
              crowns, which is why we recommend them.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Expert Advice:</span> Regular
              visits to Oaklands Dental also allow you to discuss cosmetic
              procedures like Invisalign or professional teeth whitening with
              your dentist. We can provide advice tailored to your specific
              needs and goals.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Oral Health Education:</span> We
              take the time to educate you on how to care for your oral health
              effectively. We’ll check for any oral damage caused by bad habits
              such as vaping, smoking, a poor diet, or teeth grinding, and guide
              you on how to mitigate these issues.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Preventive Care:</span> Perhaps
              most importantly, regular check-ups enable us to identify and
              address dental issues before they require more expensive and
              invasive treatments in the future.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Tips for Maintaining Oral Health Between Dental Check-Ups
            </h2>
            <p style={{ marginTop: 20 }}>
              Maintaining good oral health is key to reducing the likelihood of
              future dental issues. We recommend that you brush your teeth for
              at least two minutes twice a day and floss at least once a day.
              Additionally, maintaining a balanced diet that includes plenty of
              fruits and vegetables, limiting sugar intake, and drinking more
              water can greatly benefit your oral health. Avoiding tobacco
              products is also crucial. Lastly, consistent professional dental
              care, along with your daily hygiene routine, is essential for
              preserving your oral health between check-ups.
            </p>
            <p style={{ marginTop: 20 }}>
              Following these tips and visiting Oaklands Dental every six months
              will help keep your teeth and gums healthy in the long run. We
              make it easy for you to connect with us—request an appointment at
              your convenience, and let us help you maintain a healthy,
              confident smile.
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Preventive Dentistry</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Dental Check-ups</p>
                <p className={styles.tag}>Oral Health</p>
                <p className={styles.tag}>Preventive Care</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Dental Visits</p>
                <p className={styles.tag}>Dental Hygiene</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default PreventionDentistry;
