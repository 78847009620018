import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import styles from "./Family.module.css";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const FamilyCare = () => {
  const [temp, setTemp] = useState();
  return (
    <SpecificService
      title1="Family Care"
      title2="We are a one-stop dental care for all the busy families who get everything in one place"
      desc1="Nothing is crucial more than making a strong foundation for your oral health that lasts a lifetime.  Our experts know well how to give gentle and comprehensive care to patients of all ages."
      desc2="We believe in making a healthy relationship with our patients based on trust, personalized care, and transparent communication. Our family care dental services help adults, kids etc to get the smile they want. We primarily focus on offering restorative treatments, cosmetic enhancements, and orthodontics, Make your appointment now with our skilled dentists and get the results you need. Enjoy a healthy smile to increase your quality of life, and maintain optimal oral health. Your happiness is our top priority and this is what we are best at. 
      "
      solutionsTop={15}
    />
  );
};

export default FamilyCare;
