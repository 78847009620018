import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import styles from "./Restorative.module.css";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const RestorativeDentistry = () => {
  const temp = 10;
  return (
    <div>
      <SpecificService
        title1="Restorative Dentistry"
        title2="Reduce the need for additional dental work in the future now"
        desc1="Looking for top-tier restorative dental services? Transform your smiles and restore optimal oral health by connecting with Oaklands where we are committed to delivering excellence and a wide range of restorative treatments."
        desc2="Restorative dentistry is a specialized branch of dental care focused on repairing the function of the teeth and oral structures. This procedure helps protect the overall health and appearance of the patient’s smile. The main advantage of getting this treatment is that it not only helps regain oral health but significantly increases the quality of life as well. At Oaklands, we have a team of experts who cover your damaged teeth with ceramic dental crowns to enhance their appearance. They also fill cavities to prevent decay. Our experts provide quality dentures that fit your mouth perfectly. If you are experiencing severe pain, then it’s time to see a dentist. Connect with us and get your smile restored. We are happy to help you patients of all ages."
        solutionsTop={-20}
      />
    </div>
  );
};

export default RestorativeDentistry;
