import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./oralHealthCare.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/1.jpg";

const OralHealthCare = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}

        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              The Comprehensive Guide to Understanding and Managing Tooth
              Sensitivity
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              It is important to note that tooth sensitivity can make drinking
              hot tea or enjoying your favorite meals painful. Tooth sensitivity
              is a common issue, and if you are dealing with it, know that you
              are not alone. This condition affects many people, causing severe
              discomfort and worsening when consuming hot, cold, sweet, or
              acidic foods and drinks. Understanding the reasons for tooth
              sensitivity and knowing how to manage it can substantially improve
              your quality of life. In this blog, we at Oaklands Dental will
              explain the reasons behind tooth sensitivity and provide practical
              tips for managing it effectively.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              What Causes Tooth Sensitivity?
            </h2>
            <p style={{ marginTop: 20 }}>
              Tooth sensitivity occurs when the protective layers of the teeth,
              enamel, and cementum, are worn away, exposing the underlying
              dentin. Dentin contains small tubules that connect to the nerve
              endings inside your teeth. When these tubules are exposed, stimuli
              like heat, cold, or even certain substances can reach the nerves,
              causing pain.
            </p>
            <p style={{ marginTop: 20 }}>
              Common causes of tooth sensitivity include:
            </p>
            {/* <ul> */}
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Cavities</span> can expose the
              dentin, leading to sensitivity.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Receding gums</span> often
              caused by gum diseases, can expose the roots of your teeth, which
              are not covered by enamel.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Acidic foods and drinks </span>
              or specific medical conditions such as acid reflux can erode
              enamel, making teeth more sensitive.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Brushing too hard </span>
              can also wear down enamel and cause gum recession.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Grinding your</span> teeth can
              diminish enamel and cause sensitivity.
            </li>
            <p style={{ marginTop: 20 }}>
              If you are experiencing any of the above problems, they may be the
              underlying cause of your tooth sensitivity. However, you should
              consult a dental expert who can assess these causes in your case
              and suggest appropriate treatment.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Dealing with Tooth Sensitivity
            </h2>
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we recommend several strategies to manage and
              alleviate the discomfort associated with tooth sensitivity:
            </p>
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>
                Toothpaste specifically designed for sensitive teeth
              </span>{" "}
              can help block the transmission of sensation from the tooth
              surface to the nerve.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>
                Maintaining good oral hygiene
              </span>{" "}
              can prevent problems such as tooth decay and gum disease that
              contribute to sensitivity.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>
                Limiting your intake of acidic foods and drinks{" "}
              </span>
              can help protect your enamel. If you do consume them, rinse your
              mouth with water afterward.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>If you grind your teeth, </span>
              your dentist might suggest wearing a mouthguard at night to help
              prevent enamel wear and minimize sensitivity.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>In severe cases,</span> your
              dentist may recommend treatments like crowns, bonding, or inlays
              to cover exposed areas and protect the teeth.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              How Can We Help You
            </h2>
            <p style={{ marginTop: 20 }}>
              As dental experts at Oaklands Dental, we can accurately assess and
              treat your tooth sensitivity. The underlying cause of sensitivity
              varies from person to person, so it’s important to receive a
              personalized treatment plan. During your visit, we will also
              provide guidance on the oral hygiene practices and habits needed
              for maintaining good oral health. Preventive care is key to
              managing and reducing the pain associated with tooth sensitivity.
              If the damage to your teeth is severe, more intensive treatments
              may be necessary to prevent future discomfort and ensure long-term
              oral health.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              When to Visit a Dentist
            </h2>
            <p style={{ marginTop: 20 }}>
              While many cases of tooth sensitivity can be managed at home, it
              is advisable to see your dentist if:
            </p>
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              You experience excruciating pain.
            </li>
            <li style={{ paddingLeft: 30 }}>Your sensitivity worsens.</li>
            <li style={{ paddingLeft: 30 }}>
              You see signs of tooth decay or gum disease.
            </li>
            <li style={{ paddingLeft: 30 }}>
              Your sensitivity is interfering with your daily life.
            </li>
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we can accurately diagnose the cause of your
              sensitivity and recommend suitable treatments to reduce your
              discomfort. Tooth sensitivity is common, but it can be managed if
              taken seriously. By understanding the causes and implementing
              effective management strategies, you can minimize your discomfort
              and enjoy delightful foods and drinks without pain.
            </p>
            <h2 style={{ fontWeight: 700, marginTop: 20 }}>
              Do You Experience Tooth Sensitivity?
            </h2>
            <p style={{ marginTop: 20 }}>
              If you have started suffering from tooth sensitivity, there is no
              need to endure the pain, especially when you can prevent it. At
              Oaklands, we can help you alleviate the pain that tooth
              sensitivity causes so that you can enjoy anything you like. Get in
              touch with us today to enjoy a stress-free and pain-free life.
              Maintaining good oral hygiene and having regular dental check-ups
              are key to preventing and managing tooth sensitivity. If you are
              dealing with extreme pain, please don’t hesitate to seek expert
              advice from your dentist.
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Oral Health Care</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Tooth Sensitivity</p>
                <p className={styles.tag}>Pain Relief</p>
                <p className={styles.tag}>Dental Care</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Oral Hygiene</p>
                <p className={styles.tag}>Sensitive Teeth</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default OralHealthCare;
