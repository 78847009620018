import React, { useEffect, useState } from "react";
import SpecificService from "../../components/subService";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import styles from "./Emergency.module.css";
import { Card, Col, Container, Row } from "react-bootstrap";

const Emergency = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <Container style={{ marginTop: 125 }} fluid="sm">
      <p className={styles.heading}>Saturday Appointments Available</p>
      <Row>
        <Col />
        <Col lg={9} sm={12}>
          <p className={styles.text}>
            At our dental clinic, we understand that dental emergencies can
            happen at any time. That's why we are pleased to offer emergency
            appointments on Saturdays to ensure you receive the urgent care you
            need. To book an emergency appointment, please use the button below
            or <a href="tel:01484531964">call our office directly</a>.
          </p>
        </Col>
        <Col />
      </Row>
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <button className={styles.button} onClick={handleBooking}>
          Book an Appointment
        </button>
      </div>
      {/* <Row className={styles.section2}>
        <Col lg={5} sm={12}>
          <img src={headerGroup2} className={styles.headerGroup2} />
        </Col>
        <Col lg={6} sm={12}>
          <p className={styles.desc2}>
            Welcome to Oaklands Dental, your trusted dental home in Huddersfield
            since the 1960s. operating by Dr. Haroon Hussain since 2020, we are
            committed to providing exceptional dental services, including
            routine checkups, cosmetic treatments, and other crucial procedures.
            By delivering stellar services, we have become one of the renowned
            dental solution providers in the area. Our team of experts ensures
            the best patient care without any hassle. It’s time to take care of
            yourself and your smile. Reach out to us to book your appointment
            and receive top-notch treatment. Let us be your partner on your
            journey to optimal health. We are here to help you achieve your
            dental goals.
          </p>
        </Col>
      </Row> */}
      <p className={styles.heading2}>Pricing</p>

      <Row>
        <Col sm={7} lg={3}>
          <Card className={styles.card}>
            <Container>
              <p className={styles.cardHeading}>Routine</p>
            </Container>
            <Container style={{ marginTop: 33 }}>
              <p className={styles.change}>
                This charge covers any We Offer the following Routine treatments
                of the following
              </p>
            </Container>
            <Container style={{ marginTop: 16 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Examination: <span style={{ color: "#1F45B1" }}>£40</span>
              </p>
            </Container>
            <Container style={{ marginTop: 16 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                X-rays per unit: <span style={{ color: "#1F45B1" }}>£10</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Amalgam Fillings(Sliver):
                <span style={{ color: "#1F45B1" }}>£80-£100</span> per tooth
              </p>
            </Container>

            <Container style={{ marginTop: 12 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Composite Fillings:
                <span style={{ color: "#1F45B1" }}>£120-£150</span> per tooth
                (Depending on Tooth Size)
              </p>
            </Container>
            <Container style={{ marginTop: 16 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Root Canal Treatment:
                <span style={{ color: "#1F45B1" }}> £250-450</span>
              </p>
            </Container>
            <Container style={{ marginTop: 16 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Extractions:
                <span style={{ color: "#1F45B1" }}> £80-120</span> per tooth
              </p>
            </Container>
            <Container style={{ marginTop: 16 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Dentures:
                <span style={{ color: "#1F45B1" }}> £640-£800</span>
              </p>
            </Container>
            <Container style={{ marginTop: 16 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Denture repair:
                <span style={{ color: "#1F45B1" }}> £70-£120</span>
              </p>
            </Container>
            <div className={styles.routineButtonContainer}>
              <button className={styles.cardButton} onClick={handleBooking}>
                Get Started
              </button>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={7}>
          <Card className={styles.card}>
            <Container>
              <p className={styles.cardHeading}>Cosmetic</p>
            </Container>
            <Container style={{ marginTop: 25 }}>
              <p className={styles.change}>
                Approx rates for more cosmetic treatments, If you require any
                further infomation please contact us
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Composite bonding:
                <span style={{ color: "#1F45B1" }}> £250-£275</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Invisalign consultation:
                <span style={{ color: "#1F45B1" }}> £40</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Invisalign:
                <span style={{ color: "#1F45B1" }}> £3500-£3800</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Emax (porcelain) veneer:
                <span style={{ color: "#1F45B1" }}> £650-£750</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Emax (porcelain) crowns/ onlays:
                <span style={{ color: "#1F45B1" }}> £400-£650</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Teeth Whitening:
                <span style={{ color: "#1F45B1" }}> £295</span>
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Air flow Cleaning:
                <span style={{ color: "#1F45B1" }}> £85</span>
              </p>
            </Container>
            <div className={styles.cosmaticButtonContainer}>
              <button className={styles.cardButton} onClick={handleBooking}>
                Get Started
              </button>
            </div>
          </Card>
        </Col>
        <Col sm={7} lg={3}>
          <Card className={styles.card}>
            <Container>
              <p className={styles.cardHeading}>Hygienist Appointments</p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p className={styles.change}>
                We also offer hygine appointments at the following rate
              </p>
            </Container>
            <Container style={{ marginTop: 18 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Scale and polish -<span style={{ color: "#1F45B1" }}> £50</span>
              </p>
            </Container>
            <Container style={{ marginTop: 14 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Periodontal Treatment -
                <span style={{ color: "#1F45B1" }}> £70</span>
              </p>
            </Container>
            <Container style={{ marginTop: 14 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Airflow cleaning - <span style={{ color: "#1F45B1" }}>£85</span>
              </p>
            </Container>
            <div style={{}} className={styles.hygeinistButtonContainer}>
              <button className={styles.cardButton} onClick={handleBooking}>
                Get Started
              </button>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={7}>
          <Card className={styles.card}>
            <Container>
              <p className={styles.cardHeading}>Implants</p>
            </Container>
            <Container style={{ marginTop: 25 }}>
              <p className={styles.change}>
                We offer implants in house, On average implants cost £2500
                however please contact us for implants as prices can be subject
                to increases in accordance with material and laboratory charges.
                An initial Consultation fee maybe charged
              </p>
            </Container>
            <Container style={{ marginTop: 14 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Consultation -<span style={{ color: "#1F45B1" }}> £40</span>
              </p>
            </Container>
            <Container style={{ marginTop: 14 }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Per Unit -<span style={{ color: "#1F45B1" }}> £2500</span>
              </p>
            </Container>
            <div style={{}} className={styles.implantsButtonContainer}>
              <button className={styles.cardButton} onClick={handleBooking}>
                Get Started
              </button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Emergency;
