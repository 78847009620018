import SliderImage from "../../assets/images/slider/sliderImage.jpeg";
import SliderImage2 from "../../assets/images/slider/sliderImage_2.jpeg";
import SliderImage3 from "../../assets/images/slider/sliderImage_3.jpeg";
import SliderImage4 from "../../assets/images/slider/sliderImage_4.jpeg";
import SliderImage5 from "../../assets/images/slider/sliderImage_5.jpeg";
import SliderImage6 from "../../assets/images/slider/sliderImage_6.jpeg";

export default [
  {
    id: 1,
    url: "/sample-video.mp4",
  },
  {
    id: 2,
    url: "/sample-video-2.mp4",
  },
  {
    id: 3,
    url: "/sample-video-3.mp4",
  },
  {
    id: 4,
    url: "/sample-video-4.mp4",
  },
  {
    id: 5,
    url: "/sample-video-5.mp4",
  },
];
