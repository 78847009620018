import BrandLogo from "../../assets/images/footer/Oakland_Logo.png";
import LocationIcon from "../../assets/images/footer/location.png";
import PhoneIcon from "../../assets/images/footer/phone.png";
import EmailIcon from "../../assets/images/footer/emailIcon.png";
import styles from "./ContactCard.module.css";
import { useNavigate } from "react-router-dom";

export default function ContactCard() {
  const navigate = useNavigate();
  return (
    <div className={styles.footerContactCard}>
      <img
        src={BrandLogo}
        alt="Brand-logo"
        className={styles.brandLogo}
        onClick={() => navigate("/home")}
      />
      {/* <p className={styles.contactCardTagline}>
        Vivamus posuere tellus venenatis felis euismod, quis fringilla sapien
      </p> */}
      <div style={{ marginTop: 30 }} />
      <div className={styles.address}>
        <div style={{ display: "flex", gap: 15 }}>
          <div>
            <img
              src={LocationIcon}
              alt="Location icon"
              className={styles.cardIcons}
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p className={styles.contactCardHeading}>Address</p>

            <span className={styles.addressDetails}>
              60 Bradford Road Huddersfield, HD1 6JE
            </span>
          </div>
        </div>
      </div>

      <div className={styles.address}>
        <div style={{ display: "flex", gap: 15 }}>
          <div>
            <img
              src={PhoneIcon}
              alt="Location icon"
              className={styles.cardIcons}
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p className={styles.contactCardHeading}>Phone</p>
            <span className={styles.addressDetails}>01484 531964</span>
          </div>
        </div>
      </div>

      <div className={styles.address}>
        <div style={{ display: "flex", gap: 15 }}>
          <div>
            <img
              src={EmailIcon}
              alt="Location icon"
              className={styles.cardIcons}
            />
          </div>
          <div style={{ marginTop: 2 }}>
            <p className={styles.contactCardHeading}>Email</p>
            <span className={styles.addressDetails}>
              HuddsDental@outlook.com
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
