import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./orthodontics.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/4.jpg";

const Orthodontics = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}

        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              Invisalign vs. Braces: Cost, Comfort, and Convenience
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              Choosing the right orthodontic treatment is a significant
              decision, and at Oaklands Dental, we're here to help guide you
              through it. If you're considering straightening your teeth, you
              may be weighing your options between conventional braces and
              Invisalign. Both methods are effective, but they offer different
              features and benefits. Understanding these differences will help
              you make an informed decision based on your unique dental needs.
            </p>
            <h2 style={{ fontWeight: 700, marginTop: 20 }}>
              Do Invisalign and Braces Deliver the Same Results?
            </h2>
            <p style={{ marginTop: 20 }}>
              Both Invisalign and braces aim to align your teeth and correct
              bite issues, but they do so in different ways. Traditional braces
              use metal brackets and wires that are fixed to your teeth,
              gradually moving them into the desired position. On the other
              hand, Invisalign uses a series of removable, custom-made aligners
              that you switch out every week. Both methods can achieve the smile
              you’ve always wanted, but the approach and experience differ.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              How Invisalign Works?
            </h2>
            <p style={{ marginTop: 20 }}>
              Invisalign works by applying gentle, continuous pressure to your
              teeth, encouraging them to shift over time. The process begins
              with a 3D digital scan of your teeth, which is used to create a
              personalized treatment plan. Small, three-dimensional ridges
              called attachments are often placed on your teeth to help guide
              their movement. These aligners are designed to fit over the
              attachments, creating specific force systems to move your teeth
              effectively.
            </p>
            <p style={{ marginTop: 20 }}>
              During treatment, you'll visit your orthodontist regularly to
              check on your progress and receive new aligners. You'll need to
              wear your aligners for 20 to 22 hours each day, removing them only
              to eat or drink. Once your treatment is complete, a clear retainer
              will be necessary to maintain your new smile.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              How Much Does Invisalign Cost?
            </h2>
            <p style={{ marginTop: 20 }}>
              The cost of Invisalign treatment can range from £2,300 to £4,600,
              depending on the complexity of your dental needs and treatment
              goals. While Invisalign tends to be more expensive than
              traditional braces, the exact cost varies from person to person.
            </p>
            <p style={{ marginTop: 20 }}>
              For a detailed and personalized quote, please contact us at
              Oaklands Dental on 01484 531964. Our friendly team is here to
              provide you with accurate information and discuss your options in
              more detail.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Pros and Cons of Invisalign
            </h2>
            <p style={{ marginTop: 20 }}>
              There are several reasons to select Invisalign, but you should see
              its pros and cons too then decide.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Pros of Invisalign
            </h2>
            {/* <ul> */}
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Clear and Discreet:</span>{" "}
              Invisalign is nearly invisible, making it a more aesthetically
              pleasing option.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Removable:</span> The aligners
              can be removed for eating and brushing, leading to better oral
              hygiene and less plaque buildup.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Comfortable:</span> Invisalign
              aligners are smooth and gentle on your gums and cheeks.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Backup Trays: </span> If a tray
              breaks, you can use a previous tray as a backup until you can
              visit your orthodontist.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Cons of Invisalign
            </h2>
            {/* <ul> */}
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: "bold" }}>Maintenance:</span> The
              aligners need to be cleaned and maintained properly.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: "bold" }}>Compliance: </span>You need
              to wear the aligners consistently, or your treatment may be
              delayed.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: "bold" }}>Limited Use: </span>
              Invisalign may not be suitable for complex orthodontic cases.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: "bold" }}>Easily Lost: </span>Since
              they’re removable, aligners can be misplaced if not handled
              carefully.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Understanding Braces
            </h2>
            <p style={{ marginTop: 20 }}>
              Braces come in several types, including metal and ceramic options.
              Metal braces are made from stainless steel, while ceramic braces
              are designed to blend with your natural tooth color. Both types
              use wires held in place with rubber bands, which can be customized
              in various colors at each visit to personalize your look.
            </p>
            <p style={{ marginTop: 20 }}>
              For those who prefer a more discreet option, lingual braces are
              attached to the back of the teeth, making them invisible from the
              front. Another option is self-ligating braces, which function like
              traditional braces but use metal doors instead of elastic bands to
              hold the wires in place.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              How Much Do Braces Cost?
            </h2>
            <p style={{ marginTop: 20 }}>
              The cost of braces treatment can range from £1,500 to £10,000,
              depending on the complexity of your treatment needs.
            </p>
            <p style={{ marginTop: 20 }}>
              For a detailed and personalized quote, please contact us at
              Oaklands Dental on 01484 531964. Our friendly team is here to
              provide you with accurate information and discuss your options in
              more detail.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Pros & Cons of Braces
            </h2>
            <p style={{ marginTop: 20 }}>
              Traditional metal braces are being used by several people for
              obvious reasons as they are effective and versatile.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>Pros of Braces</h2>
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>No Compliance Needed:</span>{" "}
              Once braces are attached, they work continuously without needing
              daily attention, other than proper dental hygiene.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Effective:</span> Braces are
              highly effective for a wide range of dental issues, including
              complex cases like impacted teeth.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Customization:</span> You can
              personalize your braces with different colored bands.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Durability:</span> There’s no
              risk of losing your braces, unlike removable aligners.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>Cons of Braces</h2>
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Aesthetic concerns:</span> The
              appearance of braces can be a source of self-consciousness for
              some individuals.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Discomfort:</span> The process
              of wearing braces can cause occasional discomfort.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Food trapping:</span> Braces can
              trap food particles, requiring extra attention to oral hygiene.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Hygiene Challenges:</span>{" "}
              Braces can make it easier for bacteria and plaque to accumulate,
              potentially leading to stains on teeth when the braces are
              removed.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Food Restrictions:</span>{" "}
              Consuming sticky foods can damage braces, leading to discomfort
              and prolonged treatment times.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>Key Takeaways</h2>

            <p style={{ marginTop: 20 }}>
              Both braces and Invisalign are effective at straightening teeth
              and improving your smile. The right choice for you depends on your
              specific dental needs, lifestyle, and personal preferences. At
              Oaklands Dental, we’re here to help you navigate your options and
              choose the treatment that best fits you. Schedule a consultation
              with us to discuss which option might be the best fit for you and
              your smile.
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Orthodontics</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Invisalign</p>
                <p className={styles.tag}>Braces</p>
                <p className={styles.tag}>Orthodontic Treatment</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Cost Comparison</p>
                <p className={styles.tag}>Comfort</p>
                <p className={styles.tag}>Teeth Straightening</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default Orthodontics;
