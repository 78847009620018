import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./oralHygieneTips.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/3.jpg";

const OralHygieneTips = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}

        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              Brushing 101: Common Mistakes and How to Avoid Them
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              At Oaklands Dental, we know that perfecting your tooth-brushing
              game is essential for maintaining excellent oral health. Oral
              diseases impact the lives of 3.9 billion people worldwide, with
              conditions like gum disease and tooth decay affecting both
              children and adults. Tooth brushing is a fundamental practice in
              promoting good dental health, but just because it’s a part of your
              daily routine doesn’t mean you’re doing it correctly.
            </p>
            <p style={{ marginTop: 20 }}>
              Let’s dive into the significance of proper dental hygiene and
              uncover common brushing mistakes that may be damaging your efforts
              to achieve a beautiful smile.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Neglecting to Brush for a Full Two Minutes
            </h2>
            <p style={{ marginTop: 20 }}>
              Dentists recommend brushing for a full two minutes each time you
              brush your teeth If this is new to you, you are not alone.Research
              shows that many people brush for only about one minute and 52
              seconds, which is a great start, but aiming for the full two
              minutes is crucial. We also suggest brushing at least twice a day.
              If you’re not meeting that goal yet, here are some tips:
            </p>
            {/* <ul> */}
            <li style={{ marginTop: 20, paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Divide and Conquer: </span>
              Split your mouth into four sections—upper right, upper left, lower
              left, and lower right. Spend 30 seconds on each section, and don’t
              forget your tongue and the roof of your mouth.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Set a Timer:</span> Use your
              phone’s timer to ensure you’re brushing for the full two minutes.
            </li>
            <li style={{ paddingLeft: 30 }}>
              <span style={{ fontWeight: 700 }}>Choose the Right Brush: </span>
              Make sure your toothbrush can reach every corner and clean
              thoroughly.
            </li>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Using the Wrong Brush
            </h2>
            <p style={{ marginTop: 20 }}>
              The right toothbrush is essential for reaching all areas of your
              teeth and gums, whether it’s manual or electric. If your
              toothbrush can’t clean every surface, you increase the risk of
              tooth decay and other dental issues. At Oaklands Dental, we
              recommend using a toothbrush with soft bristles, as they’re
              flexible enough to reach difficult areas and gentle on your gums.
              Hard bristles can actually do more harm than good. It’s vital to
              ensure your brush is up to the task of keeping your entire mouth
              clean.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Incorrect Brushing Technique
            </h2>
            <p style={{ marginTop: 20 }}>
              Many people don’t brush properly or apply too much pressure. Our
              experts advise brushing your teeth at a 45-degree angle in a
              circular motion on both the outside and inside surfaces. Remember,
              brushing harder doesn’t mean brushing better—it can actually
              irritate your teeth and gums. Moreover, Plaque is a soft substance
              that can be removed with gentle, massaging pressure from your
              toothbrush.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Brushing Right After Your Meal
            </h2>
            <p style={{ marginTop: 20 }}>
              Brushing immediately after eating can do more harm than good,
              especially if you’ve consumed acidic foods or drinks. Acids from
              meals—found in juices, citrus fruits, coffee, energy drinks, and
              soda—can soften your enamel. Brushing right after eating these
              foods can wear away this softened enamel, exposing the dentin
              beneath and increasing your risk of tooth decay. Instead, we
              recommend rinsing your mouth with water right after eating and
              waiting at least 30 minutes to an hour before brushing.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              Skipping Flossing
            </h2>
            <p style={{ marginTop: 20 }}>
              Brushing alone doesn’t clean your teeth completely. Flossing is a
              simple yet highly effective way to clean the spaces between your
              teeth and remove particles from the gum line. For the best
              results, we recommend flossing before you brush.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>Final Thoughts</h2>
            <p style={{ marginTop: 20 }}>
              Now that you’re equipped with the knowledge to brush your teeth
              properly, you’re on your way to better oral health. If you’re
              looking for more information on dental care or have any questions,
              feel free to reach out to us at Oaklands Dental. We’re here to
              provide the best possible care for your teeth and help you
              maintain a healthy, confident smile.
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Oral Hygiene Tips</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Brushing Techniques</p>
                <p className={styles.tag}>Oral Care</p>
                <p className={styles.tag}>Dental Hygiene</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Common Mistakes</p>
                <p className={styles.tag}>Toothbrush</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default OralHygieneTips;
