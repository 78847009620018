import logo from "./logo.svg";
import "./globalStyles.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/Layout";
import Routes from "./routes";
import AllRoutes from "./routes";
import { useEffect } from "react";

function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <AllRoutes />
          <ToastContainer />
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
