import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./pediatricIndustry.module.css";
import GetInTouch from "../../../components/GetInTouch";
import { useNavigate } from "react-router-dom";
import Banner from "../../../assets/images/blogs/7.jpg";

const PediatricDentistry = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        {/* <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button}>Book an Appointment</button>
        </div> */}

        <Row className={styles.blogContainer}>
          <Col lg={8}>
            <h2 style={{ fontWeight: 700 }}>
              Ultimate Guide to Children's Dental Health: Tips for Preventing
              Tooth Decay and Beyond
            </h2>
            <img
              src={Banner}
              style={{ marginTop: 20 }}
              width="100%"
              alt="banner"
            />
            <p style={{ marginTop: 20 }}>
              Ensuring your child's optimal oral health is essential for their
              overall well-being. At Oaklands Dental, we understand that
              establishing a solid foundation in dental care goes beyond
              preventing cavities—it sets the stage for a lifetime of good
              habits. As a parent, you play a crucial role in maintaining your
              child’s oral hygiene. This guide will provide you with effective
              dental tips to keep your child's teeth healthy and bright, helping
              them enjoy a confident smile for years to come.
            </p>
            <h2 style={{ fontWeight: 700, marginTop: 20 }}>
              Importance of Teeth Cleaning
            </h2>
            <p style={{ marginTop: 20 }}>
              Proper teeth cleaning through brushing and flossing is vital to
              prevent the buildup of plaque—a sticky, clear substance made up of
              bacteria. If left untreated, plaque can lead to:
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: 700 }}>Cavities:</span> Cavities are
              holes in the teeth that can worsen over time if not treated
              promptly. Early intervention is key to preventing the decay from
              becoming severe.
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: 700 }}>Gum Disease:</span> Plaque left
              on the teeth can irritate the gums, potentially leading to sore,
              bleeding gums, and even tooth damage or loss. It’s crucial to
              address any signs of gum issues early to prevent long-term
              complications.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              How Can Children Maintain Clean Teeth?
            </h2>
            <p style={{ marginTop: 20 }}>
              Even before your baby’s first tooth emerges, it’s important to
              wipe their gums with a clean, damp washcloth after feedings. As
              your child’s teeth come in, follow these guidelines to ensure
              their oral health:
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: 700 }}>Brushing:</span> Help your child
              brush twice a day for 2 minutes each time using a soft-bristled
              toothbrush and a small amount of fluoride toothpaste. Teach them
              to spit out the toothpaste after brushing.
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: 700 }}>Flossing:</span> As your child
              gets older, introduce flossing once a day to remove food particles
              and plaque from between their teeth.
            </p>
            <p style={{ marginTop: 20 }}>
              <span style={{ fontWeight: 700 }}>Independence:</span> Encourage
              your child to brush and floss on their own, but continue to
              supervise to ensure they are doing it effectively.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>
              When Should Children Visit the Dentist?
            </h2>
            <p style={{ marginTop: 20 }}>
              Your child should see a dentist within 6 months of their first
              tooth appearing. Early visits help find any problems early and
              make them comfortable with dental check-ups. Keep in mind that
              every child’s dental development is unique, and not all children
              will need braces. An orthodontist at Oaklands Dental can assess
              your child’s specific needs and recommend the best treatment plan.
              If you notice any concerns with your child’s dental development,
              schedule an appointment with an orthodontist to address them
              promptly and avoid more extensive treatments later.
            </p>
            <h2 style={{ marginTop: 20, fontWeight: 700 }}>Final Thoughts</h2>
            <p style={{ marginTop: 20 }}>
              Investing in your child's dental health through daily cleaning
              routines and regular dental visits is crucial for their future
              well-being. By fostering good oral hygiene habits, encouraging a
              balanced diet, and maintaining regular dental checkups, you can
              help your child enjoy a lifetime of healthy smiles. At Oaklands
              Dental, we offer exceptional pediatric dental services to support
              your child's oral health. Contact us today to schedule a call or
              book your child’s first appointment for a brighter, healthier
              smile.
            </p>
            {/* </ul> */}
          </Col>
          <Col lg={4}>
            <div>
              <p className={styles.heading2}>Category</p>
              <p className={styles.list}>Pediatric Dentistry</p>
            </div>
            <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Children's Dental Health</p>
                <p className={styles.tag}>Tooth Decay Prevention</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Pediatric Care</p>
                <p className={styles.tag}>Oral Hygiene for Kids</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>Dental Tips for Parents</p>
              </div>
              <div className={styles.tagsContainer}>
                {/* <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p> */}
              </div>
            </div>
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default PediatricDentistry;
