import React from "react";
import SpecificService from "../../components/subService";

const Sedation = () => {
  const temp = 10;
  return (
    <div>
      <SpecificService
        title1="Sedation"
        title2="Contact Oklans, where peace of mind meets expert care. Enhance your dental experience with us"
        desc1="Don't stress about your upcoming dental procedure. Experience top-notch dental care designed for patients with anxiety or phobia about routine visits."
        desc2="At Oaklands, we make sure that we provide safe care to all our patients to make them feel better and comfortable before the treatment. Sedation services are mainly provided to offer complete relaxation and an anxiety-free experience for some patients who are getting dental treatments. It helps people to undergo the treatment without any fear. Connect with us today and find out how dental sedation can benefit your practice and make your life easy. Oaklands is happy and confident to tell you that it helps you reach and maintain maximum oral health perfectly. Don't hesitate—we are accepting new patients."
        solutionsTop={15}
      />
    </div>
  );
};

export default Sedation;
