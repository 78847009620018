import React, { useState } from "react";
import styles from "./Boutique.module.css";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const BoutiqueWhitening = () => {
  const [temp, setTemp] = useState();
  return (
    <div>
      <SpecificService
        title1="Teeth Whitening"
        title2="Begin Your Journey With Oaklands Towards A Whiter And Brighter Smile
        "
        desc1="Enjoy safe and effective teeth whitening services by our dental experts. Check your suitability for this service and learn about a complete consultation process to ensure your teeth and gums are healthy."
        desc2="Teeth whitening treatment is one of the most effective treatments, starting with a consultation with an expert dentist. This procedure involves making a custom-made bleaching tray for you tailored to your teeth by taking impressions. You will get proper instructions on how to fit the trays and apply the gel for your ease. Please note that teeth whitening does not cause any sensitivity, but rather provides complete relief instead. Trusted by millions worldwide, our bespoke solutions can assist you get your desired smile. Are you interested in having a healthy smile? Contact us for the consultation and begin your journey to a brighter and healthier smile today. "
        solutionsTop={-20}
      />
    </div>
  );
};

export default BoutiqueWhitening;
