import React from "react";
import styles from "./Trusted.module.css";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import whoWeAre from "../../assets/images/about/WHO WE ARE.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import { useNavigate } from "react-router-dom";

const TrustedPartner = () => {
  const navigate = useNavigate();
  const temp = 10;
  return (
    <div>
      <Container>
        <div style={{ position: "relative" }}>
          <div
            style={{ position: "absolute", top: -10 }}
            className={styles.headingBackground}
          >
            <img
              src={whoWeAre}
              style={{ textAlign: "center", width: "100%" }}
              alt="who we are"
            />
          </div>
        </div>
        <p className={styles.trusted}>
          Your Trusted Partners In Dental Wellness
        </p>
      </Container>
      <Container>
        <Row className={styles.section2}>
          <Col className={styles.headerGroup2}>
            <img src={headerGroup2} />
          </Col>
          <Col>
            <p className={styles.paragraph}>
              The most important feature that anyone focuses on is a Smile. We
              prioritize the safety and complete satisfaction of our patients.
              We understand that a healthy smile makes someone feel confident
              and brightens up their day. Our skilled resources are there to
              assist patients of all ages in getting professional services. We
              always strive to offer a comfortable and welcoming environment
              where you feel at ease while getting quality dental care.
            </p>
            <div className={styles.buttonContainer}>
              <button
                className={styles.button}
                onClick={() => navigate("/contact-us")}
              >
                Let’s Start
              </button>
              {/* <div
                style={{
                  display: "flex",
                  gap: 5,
                  width: "-webkit-fill-available",
                }}
              >
                <button className={styles.button2}>Read more</button>
                <img
                  src={rightArrow}
                  height={11}
                  className={styles.rightArrowAlign}
                />
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrustedPartner;
