import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../../assets/images/right-arrow.png";
import headerGroup2 from "../../assets/images/headerGroup2.png";
import Invisalign1 from "../../assets/images/Invisalign/invis1.png";
import Invisalign2 from "../../assets/images/Invisalign/invis2.png";
import Invisalign3 from "../../assets/images/Invisalign/invis3.png";
import styles from "./Implants.module.css";
import GetInTouch from "../../components/GetInTouch";
import SpecificService from "../../components/subService";

const Implants = () => {
  const temp = 10;
  return (
    <div>
      <SpecificService
        title1="Implants"
        title2="Are you still juggling with uncomfortable dental treatments, consider Oaklands dental implants as a solution"
        desc1="Choosing the right dental clinic is important to ensure a successful result. Get advanced implant dental procedures to restore full arches of teeth, stabilize dentures, and make the appearance of a smile.
        "
        desc2="We are one of the best choices for dental implant procedures due to our specialized team of periodontists, prosthodontists, and surgeons. Our aim is to satisfy our clients by restoring smiles and increasing oral functionality with our implant services. Schedule consultations with the best dentists to explain their way to dental implant processes. We make tailored treatment plans according to your needs by ensuring the best possible result for your smile  We are here to assist you in case of any emergency that you might need. 
        "
        solutionsTop={50}
      />
    </div>
  );
};

export default Implants;
