import React, { useEffect, useState } from "react";
import styles from "./Pricing.module.css";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import GetInTouch from "../../components/GetInTouch";
import file1 from "../../assets/documents/EqualityAndDiversityPolicy.doc";
import file2 from "../../assets/documents/ViolenceAndAggressionPolicy.docx";
import file3 from "../../assets/documents/SafeguardingPolicy.doc";
import file4 from "../../assets/documents/DentalX.docx";
import file5 from "../../assets/documents/MissionStatement.doc";
import file6 from "../../assets/documents/WorkingTogether.doc";
import file7 from "../../assets/documents/ComplaintsHandlingPolicy.docx";
import file8 from "../../assets/documents/ClinicalGovernancePolicy.doc";
import file9 from "../../assets/documents/PracticeInformation.docx";
import file10 from "../../assets/documents/PracticeQualityPolicy.docx";
import file11 from "../../assets/documents/OurCommitmentToStatement.docx";
import file12 from "../../assets/documents/CommitmentToPatients.docx";
import file13 from "../../assets/documents/PracticePatientResponsibilities.docx";
import file14 from "../../assets/documents/StandardsOfConductPerformaceAndEthics.docx";
import file15 from "../../assets/documents/PracticeAppointmentPolicy.doc";
import file16 from "../../assets/documents/PracticeEqualityPolicy.doc";
import file17 from "../../assets/documents/NHSBANDINGPRESENT.docx";
import file18 from "../../assets/documents/NHSBANDINGPRESENT.docx";
import file19 from "../../assets/documents/AccessToInformationHeld.rtf";
import file20 from "../../assets/documents/ConfidentialityPolicy.doc";
import file21 from "../../assets/documents/DataProtectionForPatients.doc";
import file22 from "../../assets/documents/RecordsManagementPolicy.docx";
import file23 from "../../assets/documents/ConsentPolicy.doc";
import file24 from "../../assets/documents/PatientSafety.doc";
import FinanceCalculator from "../../components/Calculator";

const Pricing = () => {
  const temp = 10;
  const [fileUrl, setFileUrl] = useState(null);
  const address = window.location.pathname;
  const downloadFile = (path) => {
    setFileUrl(path);
  };
  useEffect(() => {
    if (address === "/information/pricing") {
      const pricingSection = document.getElementById("pricing");
      pricingSection.scrollIntoView({ behavior: "smooth" });
    } else if (
      address === "/information/documents" ||
      address === "/information"
    ) {
      const documentsSection = document.getElementById("documents");
      documentsSection.scrollIntoView({ behavior: "smooth" });
    } else if (address === "/information/finance") {
      const financeSection = document.getElementById("finance");
      financeSection.scrollIntoView({ behavior: "smooth" });
    }
  }, [address]);
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <>
      <Container>
        {/* <Alert variant="danger" style={{ marginTop: 150 }}>
          <span style={{ fontWeight: 700 }}>Disclaimer:</span>{" "}
          <span style={{ fontWeight: 350 }}>
            This website is under development and some information here may not
            be accurate. Please call{" "}
            <a href="tel:01484 531964">(01484 531964)</a> to confirm. Sorry for
            inconvenience.
          </span>
        </Alert> */}
        <p className={styles.heading}>Information</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 0 }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div>

        <div id="pricing" />
        <p className={styles.heading2}>Pricing</p>

        <Row>
          <Col sm={7} lg={3}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>Routine</p>
              </Container>
              <Container style={{ marginTop: 33 }}>
                <p className={styles.change}>
                  This charge covers any We Offer the following Routine
                  treatments of the following
                </p>
              </Container>
              <Container style={{ marginTop: 16 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Examination: <span style={{ color: "#1F45B1" }}>£40</span>
                </p>
              </Container>
              <Container style={{ marginTop: 16 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  X-rays per unit: <span style={{ color: "#1F45B1" }}>£10</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Amalgam Fillings(Sliver):
                  <span style={{ color: "#1F45B1" }}>£80-£100</span> per tooth
                </p>
              </Container>

              <Container style={{ marginTop: 12 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Composite Fillings:
                  <span style={{ color: "#1F45B1" }}>£120-£150</span> per tooth
                  (Depending on Tooth Size)
                </p>
              </Container>
              <Container style={{ marginTop: 16 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Root Canal Treatment:
                  <span style={{ color: "#1F45B1" }}> £250-450</span>
                </p>
              </Container>
              <Container style={{ marginTop: 16 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Extractions:
                  <span style={{ color: "#1F45B1" }}> £80-120</span> per tooth
                </p>
              </Container>
              <Container style={{ marginTop: 16 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Dentures:
                  <span style={{ color: "#1F45B1" }}> £640-£800</span>
                </p>
              </Container>
              <Container style={{ marginTop: 16 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Denture repair:
                  <span style={{ color: "#1F45B1" }}> £70-£120</span>
                </p>
              </Container>
              <div className={styles.routineButtonContainer}>
                <button className={styles.cardButton} onClick={handleBooking}>
                  Get Started
                </button>
              </div>
            </Card>
          </Col>
          <Col lg={3} sm={7}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>Cosmetic</p>
              </Container>
              <Container style={{ marginTop: 25 }}>
                <p className={styles.change}>
                  Approx rates for more cosmetic treatments, If you require any
                  further infomation please contact us
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Composite bonding:
                  <span style={{ color: "#1F45B1" }}> £250-£275</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Invisalign consultation:
                  <span style={{ color: "#1F45B1" }}> £40</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Invisalign:
                  <span style={{ color: "#1F45B1" }}> £3500-£3800</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Emax (porcelain) veneer:
                  <span style={{ color: "#1F45B1" }}> £650-£750</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Emax (porcelain) crowns/ onlays:
                  <span style={{ color: "#1F45B1" }}> £400-£650</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Teeth Whitening:
                  <span style={{ color: "#1F45B1" }}> £295</span>
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Air flow Cleaning:
                  <span style={{ color: "#1F45B1" }}> £85</span>
                </p>
              </Container>
              <div className={styles.cosmaticButtonContainer}>
                <button className={styles.cardButton} onClick={handleBooking}>
                  Get Started
                </button>
              </div>
            </Card>
          </Col>
          <Col sm={7} lg={3}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>Hygienist Appointments</p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p className={styles.change}>
                  We also offer hygine appointments at the following rate
                </p>
              </Container>
              <Container style={{ marginTop: 18 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Scale and polish -
                  <span style={{ color: "#1F45B1" }}> £50</span>
                </p>
              </Container>
              <Container style={{ marginTop: 14 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Periodontal Treatment -
                  <span style={{ color: "#1F45B1" }}> £70</span>
                </p>
              </Container>
              <Container style={{ marginTop: 14 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Airflow cleaning -{" "}
                  <span style={{ color: "#1F45B1" }}>£85</span>
                </p>
              </Container>
              <div style={{}} className={styles.hygeinistButtonContainer}>
                <button className={styles.cardButton} onClick={handleBooking}>
                  Get Started
                </button>
              </div>
            </Card>
          </Col>
          <Col lg={3} sm={7}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>Implants</p>
              </Container>
              <Container style={{ marginTop: 25 }}>
                <p className={styles.change}>
                  We offer implants in house, On average implants cost £2500
                  however please contact us for implants as prices can be
                  subject to increases in accordance with material and
                  laboratory charges. An initial Consultation fee maybe charged
                </p>
              </Container>
              <Container style={{ marginTop: 14 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Consultation -<span style={{ color: "#1F45B1" }}> £40</span>
                </p>
              </Container>
              <Container style={{ marginTop: 14 }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Per Unit -<span style={{ color: "#1F45B1" }}> £2500</span>
                </p>
              </Container>
              <div style={{}} className={styles.implantsButtonContainer}>
                <button className={styles.cardButton} onClick={handleBooking}>
                  Get Started
                </button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <div id="documents" className={styles.informationContainer}>
        <div className={styles.informationSubContainer}>
          <p className={styles.heading2}>Information</p>
        </div>
        <Container>
          <Row style={{ marginTop: 49 }}>
            <Col lg={4} sm={12}>
              <ul>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file1)}
                  >
                    Equality and diversity policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file2)}
                  >
                    Violence and Aggression policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file3)}
                  >
                    Safeguarding Policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file4)}
                  >
                    Dental x-ray examination guide
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file5)}
                  >
                    Mission Statement
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file6)}
                  >
                    Working together
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file7)}
                  >
                    Complaints handling policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file8)}
                  >
                    Clinical governance policy
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={4} sm={12}>
              <ul>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file9)}
                  >
                    Practice information
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file10)}
                  >
                    Practice quality policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file11)}
                  >
                    Our commitment to you statement / your commitment to us
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file12)}
                  >
                    Our commitment to our patients
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file13)}
                  >
                    Practice and patient responsibilities
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file14)}
                  >
                    Standards of conduct, performance and ethics
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file15)}
                  >
                    Practice appointment policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file16)}
                  >
                    Practice equality policy
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={4} sm={12}>
              <ul>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file17)}
                  >
                    NHS dental charges from 2023 - present
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file18)}
                  >
                    Claiming free treatment information
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file19)}
                  >
                    Access to information held by the practice
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file20)}
                  >
                    Confidentiality policy for the practice team
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file21)}
                  >
                    Data protection for patients
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file22)}
                  >
                    Record management policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file23)}
                  >
                    Consent policy
                  </a>
                </li>
                <li className={styles.listStyle}>
                  <a
                    href={fileUrl}
                    className={styles.listStyle}
                    download
                    onClick={() => downloadFile(file24)}
                  >
                    Patient safety
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div id="finance" />
        <div className={styles.tabeoContainer}>
          <p className={styles.heading2}>Finance Through Tabeo</p>
        </div>
        <Container>
          <div style={{ paddingTop: 78, paddingBottom: 104 }}>
            <p className={styles.financeDesc}>
              We are thrilled to tell you that we have partnered with Tabeo to
              come up with the best solutions and offer easy financing options
              that permit you to spread the cost of your dental treatment. With
              the help of this partnership, you will be able to apply for an
              interest-free loan of up to £30,000 in just 2 minutes and get a
              decision timely without any delays. After consulting, you will get
              a secure link to decide on a repayment plan that fits your budget
              and requirements well. This makes the procedure easy without
              having any hassle about the upfront costs. So, in order to be
              eligible, you should be over 18 and have a nice income as well.
              Oaklands Dental Practice is basically a credit agent, authorized
              and regulated by the financial conduct authority. Additionally, It
              works seamlessly with Tabeo to assist you in finding suitable
              financing options according to your needs. For more details,
              please visit Tabeo's website or contact our support team for a
              complete consultation.
            </p>
            <FinanceCalculator />
          </div>
        </Container>
      </div>
      <Container>
        <div style={{ marginTop: 130 }}>
          <p className={styles.heading2}>NHS Pricing</p>
        </div>
        <Row>
          <Col lg={1} />
          <Col sm={7} lg={3}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>NHS Banding (Band 1)</p>
              </Container>
              <p className={styles.cardPricing}>£26.80</p>
              <Container>
                <p className={styles.change}>
                  This charge covers any of the following
                </p>
              </Container>
              <Container>
                <p className={styles.cardDesc}>
                  Covers an examination, diagnosis and advice. If necessary, it
                  also includes X-rays, a scale and polish (if clinically
                  needed), and planning for further treatment.
                </p>
              </Container>
              <div
                style={{ textAlign: "center", marginTop: 34, marginBottom: 42 }}
              >
                {/* <button className={styles.cardButton}>Get Started</button> */}
              </div>
            </Card>
          </Col>
          <Col sm={7} lg={3}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>NHS Banding (Band 2)</p>
              </Container>
              <p className={styles.cardPricing}>£73.50</p>
              <Container>
                <p className={styles.change}>
                  This charge covers any of the following
                </p>
              </Container>
              <Container>
                <p className={styles.cardDesc}>
                  Covers all treatment included in Band 1, plus additional
                  treatment, such as fillings, root canal treatment and removing
                  teeth (extractions).
                </p>
              </Container>
              <div
                style={{ textAlign: "center", marginTop: 34, marginBottom: 42 }}
              >
                {/* <button className={styles.cardButton}>Get Started</button> */}
              </div>
            </Card>
          </Col>
          <Col sm={7} lg={3}>
            <Card className={styles.card}>
              <Container>
                <p className={styles.cardHeading}>NHS Banding (Band 3)</p>
              </Container>
              <p className={styles.cardPricing}>£319.10</p>
              <Container>
                <p className={styles.change}>
                  This charge covers any of the following
                </p>
              </Container>
              <Container>
                <p className={styles.cardDesc}>
                  Covers all treatment included in Bands 1 and 2, plus more
                  complex procedures, such as crowns, dentures and bridges.
                </p>
              </Container>
              <div
                style={{ textAlign: "center", marginTop: 57, marginBottom: 42 }}
              >
                {/* <button className={styles.cardButton}>Get Started</button> */}
              </div>
            </Card>
          </Col>
          <Col lg={1} />
        </Row>
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </>
  );
};

export default Pricing;
