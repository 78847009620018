import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Blog1 from "./../../assets/images/blogs/1.jpg";
import Blog2 from "./../../assets/images/blogs/2.jpg";
import Blog3 from "./../../assets/images/blogs/3.jpg";
import Blog4 from "./../../assets/images/blogs/4.jpg";
import Blog5 from "./../../assets/images/blogs/5.jpg";
import Blog6 from "./../../assets/images/blogs/6.jpg";
import Blog7 from "./../../assets/images/blogs/7.jpg";
import styles from "./Blogs.module.css";
import GetInTouch from "../../components/GetInTouch";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [temp, setTemp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleBooking = () => {
    window.open("https://oaklands-dental.dentr.net/book", "_blank");
  };
  return (
    <div>
      <Container style={{ marginTop: 125 }}>
        <p className={styles.heading}>Blogs</p>
        <Row>
          <Col />
          <Col lg={7} sm={12}>
            <p className={styles.text}>
              Here at Oaklands we a wide varierty of treatments from private to
              NHS funded. Please see below an approx price of our treatments
              please contact for further infomation and bookings
            </p>
          </Col>
          <Col />
        </Row>
        <div style={{ textAlign: "center", marginTop: 10 }}>
          <button className={styles.button} onClick={handleBooking}>
            Book an Appointment
          </button>
        </div>
        <Row className={styles.blogContainer}>
          <Col lg={4}>
            <Card
              style={{ border: "none", cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/oral-health-care")}
            >
              <img src={Blog1} width="100%" alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>June 20 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                The Comprehensive Guide to Understanding and Managing Tooth
                Sensitivity
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>

            <Card
              style={{ border: "none", marginTop: 52, cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/oral-hygiene-tips")}
            >
              <img src={Blog3} alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>July 8 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                Brushing 101: Common Mistakes and How to Avoid Them
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>
            <Card
              style={{ border: "none", marginTop: 52, cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/post-orthodontic-care")}
            >
              <img src={Blog5} alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>July 26 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                Beyond Braces: The Lifesaving Role of Retainers
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>
            <Card
              style={{ border: "none", marginTop: 52, cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/pediatric-dentistry")}
            >
              <img src={Blog7} alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>Aug 13 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                Ultimate Guide to Children's Dental Health: Tips for Preventing
                Tooth Decay and Beyond
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>
          </Col>
          <Col lg={4}>
            <Card
              style={{ border: "none", cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/preventive-dentistry")}
            >
              <img src={Blog2} alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>June 29 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                Beyond Brushing: The Critical Importance of Regular Dental
                Visits
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>
            <Card
              style={{ border: "none", marginTop: 80, cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/orthodontics")}
            >
              <img src={Blog4} alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>July 17 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                Invisalign vs. Braces: Cost, Comfort, and Convenience
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>
            <Card
              style={{ border: "none", marginTop: 53, cursor: "pointer" }}
              onClick={() => handleNavigation("/blogs/dental-health-awareness")}
            >
              <img src={Blog6} alt="blog-1" />
              <div className={styles.dateConainer}>
                <p className={styles.date}>Aug 4 2024</p>
                <p className={styles.date}>by Harun Hussain</p>
              </div>
              <p className={styles.title}>
                Recognizing the Common Symptoms of Tooth Decay
              </p>
              {/* <p className={styles.text}>
                Mauris vehiula nunc ut odio ncus pellentesque. Etiam et velit
                bindum, elementum diam in, lobortis tortor
              </p> */}
            </Card>
          </Col>
          <Col lg={4}>
            {/* <div>
              <p className={styles.heading2}>Categories</p>
              <p className={styles.list}>Dental Clinic</p>
              <p className={styles.list}>Our Specialist</p>
              <p className={styles.list}>Medical Consultation</p>
              <p className={styles.list}>World Dental News</p>
            </div> */}
            <div style={{ marginTop: 35 }}>
              <p className={styles.heading2}>Latest Posts</p>
            </div>
            <div className={styles.dateConainer}>
              <p className={styles.date}>June 20 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              The Comprehensive Guide to Understanding and Managing Tooth
              Sensitivity
            </p>
            <div className={styles.dateConainer}>
              <p className={styles.date}>June 29 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              {" "}
              Beyond Brushing: The Critical Importance of Regular Dental Visits
            </p>
            <div className={styles.dateConainer}>
              <p className={styles.date}>July 8 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              Brushing 101: Common Mistakes and How to Avoid Them
            </p>
            <div className={styles.dateConainer}>
              <p className={styles.date}>July 17 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              Invisalign vs. Braces: Cost, Comfort, and Convenience
            </p>
            <div className={styles.dateConainer}>
              <p className={styles.date}>July 26 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              Beyond Braces: The Lifesaving Role of Retainers
            </p>
            <div className={styles.dateConainer}>
              <p className={styles.date}>Aug 4 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              Recognizing the Common Symptoms of Tooth Decay
            </p>
            <div className={styles.dateConainer}>
              <p className={styles.date}>Aug 13 2024</p>
              <p className={styles.date}>by Harun Hussain</p>
            </div>
            <p className={styles.descr}>
              Ultimate Guide to Children's Dental Health: Tips for Preventing
              Tooth Decay and Beyond
            </p>
            {/* <div style={{ marginTop: 38 }}>
              <p className={styles.heading2}>Tags</p>
              <div className={styles.tagsContainer}>
                <p className={styles.activeTag}>clinic</p>
                <p className={styles.tag}>dental</p>
                <p className={styles.tag}>emergency</p>
              </div>
              <div className={styles.tagsContainer}>
                <p className={styles.tag}>doctors</p>
                <p className={styles.tag}>care</p>
                <p className={styles.tag}>certificates</p>
                <p className={styles.tag}>teeth</p>
              </div>
            </div> */}
          </Col>
        </Row>
        {/* <Container className={styles.pagination}>
          <div
            style={{
              marginTop: 118,
              display: "flex",
              gap: 30,
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                color: "#0028FF",
                cursor: "pointer",
              }}
            >
              1
            </p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>2</p>
            <p style={{ fontWeight: "bold", cursor: "pointer" }}>3</p>
          </div>
        </Container> */}
      </Container>
      <div className={styles.getTouchContainer}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default Blogs;
